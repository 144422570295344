import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import restar from '../assets/restar.svg'
import sumar from '../assets/sumar.svg'
import pedidos from '../assets/pedidos.svg'
import backArrow from '../assets/backArrow.svg'
import tienda from '../assets/ic_cc.svg'
import compartir from '../assets/compartir.svg'
import Spinner from '../components/spinner';
import urlServer from '../server'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';
import LazyLoad from "react-lazyload";
import AppContext from "../context/AppContext";
import Carrito  from "../components/carrito"
import CanjePuntoHistorial from "../components/CanjePuntoHistorial"
import ModalSingleButton from '../components/modalsinglebutton'
import { version } from '../../package.json'
import { left } from '@popperjs/core';
import { bottom } from '@popperjs/core';
import zIndex from '@material-ui/core/styles/zIndex';
const MySwal = withReactContent(Swal)

const tiendaUrl = 'https://paradaonline.com.ar/s/img/tapas'

const versionActual = version.replace(/\./g, '')

export default class CanjePorPunto extends React.Component {

    constructor(props) {
        super(props)
        this.state = {            
            loading: true,
            busqueda: '',     
            redirect: false,
            backendrows:[],
            producto: this.props.props.location.state ? (this.props.props.location.state.producto.ediciones ? {
                ...this.props.props.location.state.producto,
                publicacionTipoId: 1,
            } : this.props.props.location.state.producto) : null,
            flagProdutoHistorial: true,
            seleccionadasFlag: false,
            puntos: null,
            puntosBackup: null,
            carritoCantidad:0,
            diasVencimiento: null,
            diasAviso: null,
            fechaUltimaCompra: null,
            diasRestantes: null,
            carritoVirtual: [],
            arregloExcedioPuntos: [],
            timerId:[],
            incentivoPedidoCompraHabilitado: false,
            canjeHabilitado: false,
            verTerminosCondiciones: false,
            margen: 0,
            width: 0
        }
        this.myRef = React.createRef();
    }

    static contextType = AppContext;

    history = createBrowserHistory()

    validarVencimientoToken(mensaje) {
         if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            }
        }
        //this.versionResquest();
        return
    }

    actualizarPermisos = (versionDB) => {
        var infoToken = JSON.parse(localStorage.infoToken)
        var data = {
            usuarioNombre: infoToken.name,
            ambitoId: 7,
            entidadId: infoToken.entidad_id,
            permisoNombre: 'kiosco'
        }
        var url = urlServer + "/api/account/permisos/kiosco/buscar";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((permisos) => {

            infoToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] = [...permisos];
            localStorage.setItem("infoToken", JSON.stringify(infoToken));
            localStorage.setItem("versionLocal", versionDB);

            MySwal.fire({
                icon: 'info',
                title: 'Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.',
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
            }).then((result) => {
                if (result.value) {
                    this.setState({ loading: true })
                        window.location.reload();
                } else {
                    window.location.reload();
                }
            }).catch(error => {
                console.log('error', error)
            })

        }).catch((error) => {
            console.log('buscar permisos > error :', error)
        })

    }

    versionResquest = async () => {
        const headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
        }

        const url = urlServer + "/api/app/version"
        //no requiere permiso
        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            //body: JSON.stringify(data),
            headers: headers
        })
            .then(response => response.json())
            .then(result => {

                var versionDB = 0;
                if (typeof result !== 'undefined' && result.length > 0)
                {
                    versionDB = parseInt(result[0].versionValor,10);
                }

                if (versionDB > versionActual && (versionDB > localStorage.versionLocal || localStorage.versionLocal === undefined)) {
                    this.actualizarPermisos(versionDB)
                    
                }
            }).catch(error => {
                console.log('versionResquest > error :', error)
            })

        return respuesta
    }
   
    mostrarCanje = () => {
        var result = 'none';
        //muesto si sumo cuando compra en pesos o si puedo hacer canje
        if (this.state.incentivoPedidoCompraHabilitado || this.state.canjeHabilitado) {
            result = 'block';
        }
        return result;
    }

    dataTienda = async () => {

        ReactGA.event({
            category: 'Tienda/ClubParadaOnline',
            action: 'Mostrar Canje Por Puntos'
        });

        this.setState({
            loading: true
        })

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token
        }

        const data = {
            nombreTienda: "Tienda Agea"
        }

        const url = urlServer + "/api/incentivo/catalogoIncentivo/listar"


        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(resultCatalogo => {
                this.validarVencimientoToken(resultCatalogo.message);
                this.setState({
                    producto: resultCatalogo,
                    loading: true
                })
                // Recuperar ediciones del carrito
                var urlCarrito = urlServer + "/api/tienda/carrito/obtener";
                var dataCarrito = {
                    UsuarioId: null
                };

                fetch(urlCarrito, {
                    method: 'POST',
                    body: JSON.stringify(dataCarrito),
                    headers: {
                        'Authorization': 'bearer ' + localStorage.token,
                        'Content-Type': 'application/json'
                    }
                }).then(responseCarrito => {
                    return responseCarrito.json();
                }).then(resultCarrito => {
                    this.validarVencimientoToken(resultCarrito.message);
                    var totalPuntosAcumulado = 0;
                    resultCarrito.filter(e => e.cantidad > 0).map(function puntosTotalDeEdiciones(e) {
                        totalPuntosAcumulado = e.cantidad * e.puntos + totalPuntosAcumulado
                    })
                    // Recorro catalogo y precargo carrito
                    const dataCatalogo = resultCatalogo.map((edicion, index) => {
                        if (!edicion.cantidad && edicion.cantidad !== 0) {
                            edicion.cantidad = 0
                        }
                        edicion = { 
                                    ...edicion,
                                    excedido: 0,
                                    marcaExcedido: false,
                                    stockInicial: edicion.stockDisponibleAlmacen,
                                    puntosInicial: this.state.puntos + totalPuntosAcumulado,
                                    cantidadBackup: edicion.cantidad,
                                    excedioPuntos: edicion.puntosInicial < 0 ? true : false
                                };
                        var indice = resultCarrito.findIndex(ed => ed.edicionId === edicion.edicionId && ed.utilizaPunto === true)//solo los de puntos
                        if (indice !== -1) {
                            var aux = resultCarrito[indice];
                            edicion.cantidad = aux.cantidad;
                            edicion.stockInicial = edicion.cantidad + edicion.stockDisponibleAlmacen
                            edicion.puntosInicial =  this.state.puntos + totalPuntosAcumulado
                        }
                        return edicion
                    })

                    this.setState({
                        backendrows: dataCatalogo,
                        loading: false,
                        carritoCantidad: resultCarrito.length,
                        carritoVirtual: JSON.parse(JSON.stringify(resultCarrito))
                    })
                }).catch(errorCarrito => {
                    this.validarVencimientoToken(errorCarrito.message);
                    console.log("error al precargar carrito", errorCarrito);
                });

            })
            .catch(error => {
                this.validarVencimientoToken(error.message);
                console.log('error', error)
                this.setState({
                    loading: false
                })
            });
        return respuesta
    }

    borrarCantidadCarrito = () => {
        this.dataTienda()
    }

    actualizarCantidadCarrito = () => {
        ReactGA.event({
            category: 'Tienda/actualizarCantidadCarrito',
            action: 'actualizar cantidad en carrito'
        });

        this.setState({
            loading: true
        })

        // Recuperar ediciones del carrito
        var urlCarrito = urlServer + "/api/tienda/carrito/obtener";
        var dataCarrito = {
            UsuarioId: null
        };

        fetch(urlCarrito, {
            method: 'POST',
            body: JSON.stringify(dataCarrito),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json'
            }
        }).then(responseCarrito => {
            return responseCarrito.json();
        }).then(resultCarrito => {
            this.validarVencimientoToken(resultCarrito.message);

            this.setState({
                loading: false,
                carritoCantidad: resultCarrito.length
            })

        }).catch(errorCarrito => {
            this.validarVencimientoToken(errorCarrito.message);
            console.log("error al precargar carrito", errorCarrito);
        });
    }

    tiendaCarritoActualizar = (edicionId, cantidad) => {
        var url = urlServer + '/api/tienda/carrito/actualizar';
        var data = {
            usuarioId: null,
            edicionId: edicionId,
            cantidad: cantidad,
            utilizaPunto: 1
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(result => {
            this.validarVencimientoToken(result.message);
        }).catch(error => {
            this.validarVencimientoToken(error.message);
            console.log('error', error);
        });
    }

    compartir = async (edicion) => {
        //no requiere permiso
        fetch(`${tiendaUrl}/${edicion.codigoEditor}.png`)
            .then(function (response) {
                if (response.ok) {
                    response.blob()
                        .then(blobData => {
                            var file = new File([blobData], "picture.png", { type: 'image/png' });
                            var filesArray = [file];
                            if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                                navigator.share({
                                    text: `Publicacion: ${edicion.edicionDescripcionCorta} \n Precio de Venta: $${edicion.precioTapa}`,
                                    files: filesArray,
                                    title: 'Compartir publicacion',
                                })
                                    .then(() => console.log('Successful share'))
                                    .catch(error => console.log('Error sharing:', error));;
                            }
                        });
                }
            })
            .catch(function (response) {
                fetch(`${tienda}`)
                    .then(response => response.blob())
                    .then(blobData => {
                        var file = new File([blobData], "picture.png", { type: 'image/png' });
                        var filesArray = [file];
                        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                            navigator.share({
                                text: `Publicacion: ${edicion.edicionDescripcionCorta} \n Precio de Venta: $${edicion.precioTapa}`,
                                files: filesArray,
                                title: 'Compartir publicacion',
                            })
                                .then(() => console.log('Successful share imagen default'))
                                .catch(error => console.log('Error sharing imagen default:', error));;
                        }
                    })
            })
    }

    componentDidMount() {
        if (!this.state.backendrows || this.state.backendrows.length < 1) {
            this.dataTienda();
            this.puntos();
        }
        document.title = "Club Parada Online";

        // Cuando vengo desde catalogo al hacer click en canjear filtrar campo de busqueda
        if(this.props.props.location.params)
        {
            this.setState({busqueda: this.props.props.location.params.buscar})
            
        }
        this.obtenerConfiguracionIncentivoHabilitado()
        this.obtenerConfiguracionCanjeHabilitado()
        this.obtenerTerminosyCondiciones()
    }

    obtenerTerminosyCondiciones = () => {
        ReactGA.event({
            category: 'HistorialCanje',
            action: 'Obtener Terminos y Condiciones'
        });

        var url = urlServer + '/api/configuracionItem/consultar/configuracion'
        var data = {
            entornoId: 5,
            ambitoId: 4,
            clave: 'Incentivos.Terminos.Condiciones',
            entidadId: null,
            incluirItemsSinConfigurar: true
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);
            this.setState({terminosCondiciones: result[0].valor})
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error ', error);
        })

    }

    obtenerConfiguracionIncentivoHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Incentivos.PedidoCompra.Habilitado',
            entidadId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization" : "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        })
        .then((result) => {
            this.validarVencimientoToken(result.message);
            this.setState({incentivoPedidoCompraHabilitado: result[0].valor === "1" ? true: false})
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al obtener la configuracion de incentivo habilitado')
        })
    }

    obtenerConfiguracionCanjeHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Canje.Habilitado',
            entidadId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        })
            .then((result) => {
                this.validarVencimientoToken(result.message);
                this.setState({ canjeHabilitado: result[0].valor === "1" ? true : false })
            }).catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log('error al obtener la configuracion de incentivo habilitado')
            })
    }

    addDefaultSrc(ev) {
        ev.target.src = tienda
    }

    cardItem = (edicion) => {
        return (
            <div className="w-100 d-flex justify-content-between" style={{marginBottom:'10px'}} >
                <span className="subTitleItem" style={{ fontSize: '15px' }}>{edicion.edicionDescripcionCorta}</span>
                &nbsp;
                <div style={{ display: "", textAlign: "right" }}>
                    {edicion.soloCanje === true ?
                        <div><span className='d-flex text-right' style={{ whiteSpace: 'nowrap', cursor: 'pointer', justifyContent: 'right' }}> {edicion.puntos} {edicion.puntos === 1 ? 'punto' : 'puntos'}</span></div>
                        :
                        <React.Fragment>
                            <em className="priceItem" style={{ display: "block", textDecoration: edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ? 'line-through' : '', maxHeight: "18px", fontSize: edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ? '13px ' : '15px', marginLeft: '20px', height: edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ? '16px ' : '' }}>${edicion.precioVenta.toFixed(2)}</em>

                            {edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ?
                                <React.Fragment>
                                    <div><em className="priceItem" style={{ fontSize: '15px', marginLeft: '10px', whiteSpace: 'nowrap' }}>${edicion.precioConDescuento.toFixed(2)}</em></div>
                                    <div><em className="priceItem" style={{ width: "102px", fontSize: '14px', marginLeft: '0px', color: "#09b909c7", position: "relative", whiteSpace: 'nowrap' }}>{edicion.descuento}% OFF</em></div>
                                </React.Fragment>
                                :
                                <div>
                                    {edicion.etiquetaDescuento.length > 0 ?
                                        <React.Fragment>
                                            <div><em className="priceItem" style={{ fontSize: '14px', marginLeft: '0px', color: "#09b909c7", position: "relative", whiteSpace: 'nowrap' }}>{edicion.descuento}% OFF</em></div>
                                            <div><em className="priceItem" style={{ width: "102px", fontSize: '14px', marginLeft: '0px', color: "#09b909c7", position: "relative", whiteSpace: 'nowrap' }}>Costo Vendedor</em></div>
                                        </React.Fragment>
                                        : null}
                                </div>}
                            {edicion.puntos === 1 && <div><span className='d-flex text-right' style={{ whiteSpace: 'nowrap', cursor: 'pointer', justifyContent: 'right' }}>o {edicion.puntos} punto</span></div>}
                            {edicion.puntos > 1 && <div><span className='d-flex text-right' style={{ whiteSpace: 'nowrap', cursor: 'pointer', justifyContent: 'right' }}>o {edicion.puntos} puntos</span></div>}
                        </React.Fragment>}
                </div>
            </div>
        )
    }

    verCarrito = () => {
        if (this.state.carritoCantidad > 0) {
            this.setState({
                seleccionadasFlag: !this.state.seleccionadasFlag
            })
            this.props.hideMenu(true)
            if (!this.state.seleccionadasFlag) {
                ReactGA.event({
                    category: 'Tienda/ClubParadaOnline',
                    action: 'Ver Carrito de Canje por Puntos'
                });
            } else {
                this.volverDeCarrito(this.state.seleccionadasFlag, {})
            }
        }
    }

    puntos = async () => {
       
        this.setState({
            loading: true
        })

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token
        }

        const data = {
            nombreTienda: "Tienda Agea",
            puntoVentaId:null
        }

        const url = urlServer + "/api/incentivo/puntos/obtener"
        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(resultPuntos => {
                this.validarVencimientoToken(resultPuntos.message);
                var disponible = resultPuntos[0].disponible;
                var diasRestantes = resultPuntos[0].diasRestantes;
                var darAviso = resultPuntos[0].darAviso

                this.setState({
                    puntos: disponible,
                    diasRestantes: diasRestantes,
                    darAviso: darAviso,
                    loading: true
                })
            })
            .catch(error => {
                this.validarVencimientoToken(error.message);
                console.log('error', error)
                this.setState({
                    loading: true
                })
            });
        return respuesta
    }

    volverDeCarrito = (seleccionadasFlag, listado) => {
        this.dataTienda();
        this.puntos();
        this.setState({
            seleccionadasFlag: !seleccionadasFlag,
            loading:false
        })      
        this.props.hideMenu(true);
    }

    actualizarPuntos = (puntos) => {
       
        this.setState({
            puntos: this.state.puntos - puntos
        })
    }

    showAviso = () => {
        const {darAviso} = this.state;
        
        return darAviso ? 'flex' : 'none'
    }

    redondearPrecio = (numero) => {
        return numero > 0 ? numero.toFixed(2): 0;
    }

    actualizarNumeroCarrito = (cantidadCarrito) => {
        this.setState({carritoCantidad: cantidadCarrito})
    }

    calcularPuntos = (edicion) => {
        let totalPuntosAcumulado = 0;
        this.state.backendrows.filter(e => e.cantidad > 0).map(function puntosTotalDeEdiciones(e) {
            totalPuntosAcumulado = e.cantidad * e.puntos + totalPuntosAcumulado
        })
        let totalPuntos = edicion.puntosInicial - (totalPuntosAcumulado ? totalPuntosAcumulado : 0)
        return totalPuntos
    }

    productoListadoConInput = () => {
        return (this.state.backendrows
                    .filter(e => (e.edicionId > 0))
                        .filter(a => {const aSinProductoGrupoDescripciones = { ...a };
                            delete aSinProductoGrupoDescripciones.productoGrupoDescripciones;
                            const strA = JSON.stringify(Object.values(aSinProductoGrupoDescripciones)).toLowerCase();
                            const strBusqueda = this.state.busqueda.toLowerCase();
                            return strA.indexOf(strBusqueda) !== -1;
                        })
                        .map((edicion, index) => {
            return (<div key={index} className="d-flex justify-content-between days itemTienda" >
                <LazyLoad style={{height: '100%'}}>
                    <div className="whatsapp-share-button">
                        <ReactSVG src={compartir} style={{ cursor: 'pointer' }} onClick={() => this.compartir(edicion)} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <figure>
                            <div>
                                {edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ? <figcaption className="titleTagDescuentoItem">{edicion.etiquetaDescuento}</figcaption> : null}
                                <img src={edicion.imagen ? `data:image/jpeg;base64,${edicion.imagen}` : `${tiendaUrl}/${edicion.codigoEditor}.png`}
                                    onError={this.addDefaultSrc}
                                    style={{ maxWidth: "150px" }}
                                    alt="placeholder" />
                                
                                {edicion.tagMasVendido !== "" ? <figcaption style={{position: 'relative', bottom: '5px'}} className="titleTagItem">{edicion.tagMasVendido}</figcaption> : null}
                            </div>
                        </figure>

                        <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'column', justifyContent:'space-between', height: '100%' }}>
                            {this.cardItem(edicion)}
                            <div className="w-100 footerItem align-items-center d-flex justify-content-between">
                                <div className="f-14 fw-500" style={{ maxWidth: '66%', fontWeight: 'bold' }}>
                                    <span>Stock disponible: {edicion.stockDisponibleAlmacen < 0 ? 0 : edicion.stockDisponibleAlmacen }</span> <br/>
                                    <div id={`excesoStock-${edicion.edicionId}`} style={{display: this.mostrarExcesoStock(edicion), color: 'red'}}>El pedido sobrepasa el stock por {this.calcularExcedentePedido(edicion)} ejemplar/res</div>
                                    <div style={{display:  this.state.arregloExcedioPuntos.includes(edicion.edicionId) && this.state.puntos < 0  ? 'inline' : 'none', color: 'red' }}>Sus puntos no son suficientes.</div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center" style={{ width: '105px', position: 'relative', right: '5px' }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ cursor: 'pointer', background: !edicion.cantidad ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%' }} onClick={() => {
                                        if (edicion.cantidad > 0) {
                                            edicion.cantidad--
                                            edicion.stockDisponibleAlmacen = edicion.stockDisponibleAlmacen+1
                                            edicion.cantidadBackup = edicion.cantidad
                                            let totalPuntos = this.calcularPuntos(edicion)
                                            if (this.state.puntos >= 0 && edicion.stockDisponibleAlmacen >= 0) {
                                                this.checkearEdicionEnCarrito(edicion)
                                                this.checkearEdicionEnArregloPuntos(edicion, totalPuntos)
                                                this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad)
                                            }
                                            let { backendrows } = this.state
                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                            backendrows[ind] = edicion
                                            this.setState({ 
                                                backendrows,
                                                puntos: totalPuntos
                                            })
                                        }
                                    }}>
                                        <ReactSVG src={restar} style={{ color: !edicion.cantidad ? '#EAEAEA' : '#8E95A5', width: '11px' }} />
                                    </div>
                                    <div className="f-13-5">
                                        <input
                                            style={{
                                                backgroundColor: this.definirBackGround(edicion), 
                                                border: '#343435 solid 1px'
                                            }}
                                            type='number' 
                                            className='input-of-cantidades text-center'
                                            maxLength='5'
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 5)}
                                            min="0"
                                            max="99999"
                                            id={`tienda-${edicion.edicionId}`}
                                            value= {edicion.cantidad}
                                            autoComplete="off"
                                            onFocus={(e) => {
                                                e.target.select()
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter") {
                                                    e.currentTarget.blur()
                                                }
                                            }}
                                            onBlur={(e) => {
                                                let { backendrows } = this.state;
                                                let regex = /[^0-9]/g
                                                if (regex.test(e.target.value)) {
                                                    e.target.value = e.target.value.replace(regex,'')
                                                }
                                                edicion.cantidad = e.target.value === "" || edicion.cantidad === "empty" ? 0 : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen = edicion.stockInicial - (edicion.cantidad === "empty" ? 0 : edicion.cantidad);
                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                backendrows[ind] = edicion
                                                this.setState({ backendrows })

                                                if (edicion.stockDisponibleAlmacen >= 0 && this.state.puntos >= 0 && edicion.cantidadBackUp !== edicion.cantidad)  {
                                                    edicion.cantidadBackUp = edicion.cantidad
                                                    this.checkearEdicionEnCarrito(edicion)
                                                    this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad)
                                                } else if(edicion.stockDisponibleAlmacen < 0) {
                                                    MySwal.fire({
                                                        icon: 'error',
                                                        title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                        showConfirmButton: true,
                                                        confirmButtonText: 'Aceptar'
                                                    });
                                                }
                                            }}
                                            onChange={(e) => {
                                                let { backendrows } = this.state;
                                                let regex = /[^0-9]/g
                                                if (regex.test(e.target.value)) {
                                                    e.target.value = e.target.value.replace(regex,'')
                                                }
                                                e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                edicion.cantidad = e.target.value === "" ? 'empty' : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen = edicion.stockInicial - (edicion.cantidad === "empty" ? 0 : edicion.cantidad);
                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                backendrows[ind] = edicion
                                                let totalPuntos = this.calcularPuntos(edicion)
                                                this.checkearEdicionEnArregloPuntos(edicion, totalPuntos)
                                                this.setState({ 
                                                    backendrows,
                                                    puntos: totalPuntos
                                                })
                                            }}
                                        /> 
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center" style={{ cursor: 'pointer', background: edicion.stockDisponibleAlmacen <= 0 || this.state.puntos <= 0 || this.state.puntos - edicion.puntos <= 0? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%' }} onClick={() => {
                                        if (edicion.stockDisponibleAlmacen > 0 &&  ( this.state.puntos > 0 && this.state.puntos - edicion.puntos >= 0)) {
                                            edicion.cantidad++
                                            edicion.stockDisponibleAlmacen = edicion.stockDisponibleAlmacen-1
                                            edicion.cantidadBackup = edicion.cantidad
                                            let totalPuntos = this.calcularPuntos(edicion)
                                            this.checkearEdicionEnCarrito(edicion)
                                            this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad)
                                            let { backendrows } = this.state
                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                            backendrows[ind] = edicion
                                            this.setState({ 
                                                backendrows,
                                                puntos: totalPuntos
                                            })
                                        }
                                    }}>
                                        <ReactSVG src={sumar} style={{ color: edicion.stockDisponibleAlmacen < 0 || this.state.puntos < 0 || this.state.puntos - edicion.puntos < 0? '#EAEAEA' : '#8E95A5', width: '11px', height: '18px' }} />
                                    </div>
                                    <div>
                                        {/* Se agrego para que no seleccione la imagen al hacer doble click en sumar */}
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </LazyLoad>
            </div>)
        })
        )
    }

     // inicio funciones para input
    calcularExcedentePedido = (edicion) => {
        return Math.abs(edicion.stockInicial - edicion.cantidad)    
    }

    mostrarExcesoStock = (edicion) => {
        //let { backendrowsBackUp } = this.state
        return edicion.stockInicial - edicion.cantidad < 0 ? 'inline' : 'none'
    }

    definirBackGround = (edicion) => {
        var color = 'white'
        if(this.state.arregloExcedioPuntos.includes(edicion.edicionId) || edicion.cantidad === 'cero' || edicion.cantidad === 'empty') {
            color = 'hsla(14, 100%, 53%, 0.5)'
        }
            
        return color
    }

    checkearEdicionEnArregloPuntos = (edicion, totalPuntos) => {
        const { arregloExcedioPuntos } = this.state;
        var ind = arregloExcedioPuntos.findIndex((id) => edicion.edicionId === id);

        if (totalPuntos < 0 && ind === -1) {
            arregloExcedioPuntos.push(edicion.edicionId);
        } else if (totalPuntos >= 0) {
            arregloExcedioPuntos.splice(0,arregloExcedioPuntos.length);
        }
    }

    checkearEdicionEnCarrito = (edicion) => {
        const { carritoVirtual } = this.state
        // Para incrementar el numero del carrito, checkeo si la edicion a la que estoy aumentando se encuentra en el carrito.
        let ind = carritoVirtual.findIndex(e => e.edicionId === edicion.edicionId && e.puntos)
        if (ind === -1 && edicion.cantidad > 0) {
            carritoVirtual.push(edicion)
            this.setState({
                carritoCantidad: edicion.cantidad === 0 ?  this.state.carritoCantidad : this.state.carritoCantidad + 1,
                carritoVirtual
            })
        } else if (edicion.cantidad === 0 && ind !== -1) {
            carritoVirtual.splice(ind, 1)
            this.setState({
                carritoCantidad: edicion.cantidad === 0 ?  this.state.carritoCantidad - 1 : this.state.carritoCantidad,
                carritoVirtual
            })
        }
    }
    // fin funciones para input

    render() {
        const { loading, seleccionadasFlag, incentivoPedidoCompraHabilitado, verTerminosCondiciones } = this.state

        return (
            <React.Fragment>

                {/*se utiliza para volver del carrito*/}
                {/*tendriamos que mejorar esto*/}
                {!this.state.seleccionadasFlag ? 
                    
                        localStorage.getItem('flagCatalogoCategoria') && localStorage.getItem('flagVolverDeCanje') ? 
                            <div id="backarrow" className="position-fixed back-arrow-box"
                                onClick={() => {
                                    localStorage.removeItem('flagVolverDeCanje');
                                    this.props.props.history.push({
                                        pathname: '/Tienda/CargarPedido'
                                    })
                                }}>
                                <ReactSVG src={backArrow} />
                            </div>:null
                    
                    :
                    <div id="backarrow" className="position-fixed back-arrow-box"
                        onClick={() => {
                            this.volverDeCarrito(this.state.seleccionadasFlag, {})
                            // this.setState({ seleccionadasFlag: !this.state.seleccionadasFlag })
                            this.props.hideMenu(false)
                        }}
                        >
                        <ReactSVG src={backArrow} />
                    </div>
                }

                <div id='cargarpedido' className="tienda container" 
                                style={{position: verTerminosCondiciones ? 'fixed': '', 
                                left: '50%', 
                                transform: verTerminosCondiciones ? 'translateX(-50%)': '',
                                paddingBottom: '100px'}}>
                    <div className="d-flex justify-content-between">
                        <Title classes="" title={seleccionadasFlag ? "Ediciones seleccionadas" : "Club Parada Online"} />
                        {/*BOTON CARRITO SUPERIOR*/}
                        <div className="position-relative"
                            style={{ marginTop: '33px' }}
                            >
                            <ReactSVG src={pedidos} style={{ width: '27px', height: '27px', color: '#8E95A5' }} />
                            <div className="position-absolute d-flex justify-content-center align-items-center f-11"
                                style={{
                                    right: '-8px',
                                    top: '-8px',
                                    background: '#EA3F3F',
                                    color: 'white',
                                    borderRadius: '50%',
                                    width: '16px',
                                    height: '16px'
                                }}>
                                {this.state.carritoCantidad }
                            </div>
                        </div>
                    </div>
                   
                    <div className="titulo-producto">
                        {/* {producto.descripcion}*/}
                    </div>

                    {loading ?
                        <Spinner style={{ fontSize: '8px' }} /> :
                        (<React.Fragment>
                            {seleccionadasFlag ?
                                <div style={{ paddingBottom: '95px' }}>
                                    {/* Carrito */}
                                    <Carrito volverDeCarrito={this.volverDeCarrito}
                                        borrarCantidadCarrito={this.borrarCantidadCarrito}
                                        actualizarPuntos={this.actualizarPuntos}
                                        actualizarNumeroCarrito={this.actualizarNumeroCarrito}
                                        puntos={this.state.puntos}
                                        props={this.props.props} />
                                </div>
                                : (<React.Fragment>
                                     <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize:'16px', paddingBottom:'5px', color:'#224372'}}>
                                        <div style={{display: this.mostrarCanje()}}>
                                            <span>Puntos: {this.state.puntos <= 0 ? 0 : this.state.puntos }</span><br />
                                            <div className='alerta-puntos' style={{display: this.showAviso()}}> 
                                                <span>Tus puntos se venceran en {this.state.diasRestantes} {this.state.diasRestantes === 1 ? 'dia' : 'dias'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pedido" style={{ paddingBottom: '25px', marginBottom: '0px' }}> {/*padding con destacados*/}

                                        {/* Campo de busqueda, para productos y historial */}
                                        <div className="w-100" style={{ marginBottom: '-20px' }}>
                                            <input className="w-100 form-control"
                                                type="text"
                                                placeholder="Buscar"
                                                onChange={(e) => {
                                                    if(this.state.flagProdutoHistorial)
                                                    {
                                                        this.setState({ busqueda: e.target.value });
                                                    }
                                                    else{
                                                        this.setState({ busqueda: e.target.value }, () => {
                                                            this.myRef.current.historialCanjeDePuntos();
                                                        });
                                                    }
                                                    
                                                }}

                                                value={this.state.busqueda} />
                                        </div>

                                        {/* Pestañas productos y historial */}
                                        <div className="tabs d-flex justify-content-between w-100 " style={{ borderBottom: '1px solid gray', marginBottom: '18px' }}>
                                            <div className={"tablinks col-6 text-center" + (this.state.flagProdutoHistorial ? ' active' : '')}
                                                onClick={(e) => {
                                                    this.setState({ flagProdutoHistorial: true })
                                                }}>
                                                Productos
                                            </div>
                                            <div className={"tablinks col-6 text-center" + (!this.state.flagProdutoHistorial ? ' active' : '')} onClick={(e) => {
                                                this.setState({ flagProdutoHistorial: false })
                                                this.props.hideMenu(false)
                                            }}>Hist. de Carga/Puntos</div>
                                        </div>

                                        <div className={` ${!this.state.flagProdutoHistorial ? 'historial-canje' : 'historial'}`}>
                                            {/* Productos a canjear */}
                                            {this.state.flagProdutoHistorial ? (
                                                this.productoListadoConInput()
                                            ) : null}

                                            {/* Historial canje de puntos */}
                                            {!this.state.flagProdutoHistorial ? (
                                               <CanjePuntoHistorial carritoCantidad={this.state.carritoCantidad} desdeCanje={true} busqueda={this.state.busqueda} ref={this.myRef}/>

                                            ): null}
                                            
                                        </div>
                                    </div>
                                    </React.Fragment>
                                    )
                            }
                            
                            
                        </React.Fragment>
                        )}
                </div>
                { !this.state.seleccionadasFlag && this.state.carritoCantidad > 0  && Object.keys(this.state.timerId).length === 0 ?
                    <div>
                        <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar-club-paradaonline">
                            {/*BOTON CARRITO INFERIOR*/}
                            <div className="d-flex justify-content-center align-items-center"
                                onClick={() => {
                                    this.verCarrito()
                                }}
                                style={{
                                    background: "#224372",
                                    color: "white",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderRadius: "16px",
                                    width: "105px",
                                    height: "33px"
                                }}> {"Ver Carrito"}
                            </div>
                        </div>
                        <div id="barra-terminos-condiciones" className="d-flex justify-content-center align-items-center barra-terminos-condiciones">
                            <div className="d-flex justify-content-center align-items-center"
                                onClick={() => {
                                    this.setState({verTerminosCondiciones: true})

                                }}
                                style={{
                                color: "#d33",
                                fontSize: "12px",
                                textAlign: "center",
                                cursor: "pointer",
                                borderRadius: "16px",
                                width: "190px",
                                height: "33px",
                                fontWeight: 'bold'
                                }}
                            >
                                Ver t&eacute;rminos y condiciones
                            </div>
                        </div>
                    </div>
                    : null
                    
                }

                {this.state.carritoCantidad < 1 && !this.state.seleccionadasFlag &&
                    <div id="barra-terminos-condiciones" className="d-flex justify-content-center align-items-center barra-terminos-condiciones">
                        <div className="d-flex justify-content-center align-items-center"
                            onClick={() => {
                                this.setState({verTerminosCondiciones: true})

                            }}
                            style={{
                            color: "#d33",
                            fontSize: "12px",
                            textAlign: "center",
                            cursor: "pointer",
                            borderRadius: "16px",
                            width: "190px",
                            height: "33px",
                            fontWeight: 'bold'
                            }}
                        >
                            Ver t&eacute;rminos y condiciones
                        </div>
                    </div>
                }
                <ModalSingleButton verModal = {verTerminosCondiciones} 
                            titulo = "T&eacute;rminos y Condiciones"
                            accion ={() => {
                                this.setState({verTerminosCondiciones: false})
                            }}
                            textoBoton = 'Cerrar'>
                    <div className="text-justify" style={{fontSize:'11px'}} dangerouslySetInnerHTML={{__html: this.state.terminosCondiciones}}></div>
                </ModalSingleButton>
            </React.Fragment>
        )
    }
}