import * as React from "react";
import { Redirect } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Title } from "../components/title";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import pedidos from "../assets/pedidos.svg";
import backArrow from "../assets/backArrow.svg";
import tienda from "../assets/ic_cc.svg";
import compartir from "../assets/compartir.svg";
import Spinner from "../components/spinner";
import urlServer from "../server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import LazyLoad from "react-lazyload";
import Carousel from "react-bootstrap/Carousel";
import AppContext from "../context/AppContext";
import { version } from "../../package.json";
import Carrito from "../components/carrito";
import ModalProductoNoEditorial from "../components/modalproductonoeditorial";
import AsyncSelect from "react-select/async";

const MySwal = withReactContent(Swal);

const tiendaUrl = "https://paradaonline.com.ar/s/img/tapas";
const versionActual = version.replace(/\./g, "");

export default class CargarPedido extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: this.props.props.location.state
        ? this.props.props.location.state.producto.ediciones
          ? {
              ...this.props.props.location.state.producto,
              publicacionTipoId: 1,
            }
          : this.props.props.location.state.producto
        : null,
      loading: false,
      busqueda: "",
      busquedaPorCategoria: "",
      busquedaTextoMostrarEnInput: "",
      busquedaPorEdicionBanner: "",
      publicationCategoria: [],
      categoriaSeleccionada: null,
      resultPublicacionCategoria: [],
      resultTiendaCatProdMasVendido: [],
      redirect: false,
      flag: true,
      flagCatalogoCategoria: true,
      backendrows:
        this.props.props.location.state &&
        this.props.props.location.state.producto.ediciones
          ? this.props.props.location.state.producto.ediciones.map(
              (edicion) => {
                if (!edicion.cantidad && edicion.cantidad !== 0) {
                  edicion.cantidad = 0;
                }
                return edicion;
              }
            )
          : [],
      pedido: [],
      successCC: false,
      seleccionadasFlag: false,
      puntos: null,
      diasSemana: [
        {
          dia: "Lunes",
          DiaSemana: 2,
          cantidad: 0,
        },
        {
          dia: "Martes",
          DiaSemana: 3,
          cantidad: 0,
        },
        {
          dia: "Miércoles",
          DiaSemana: 4,
          cantidad: 0,
        },
        {
          dia: "Jueves",
          DiaSemana: 5,
          cantidad: 0,
        },
        {
          dia: "Viernes",
          DiaSemana: 6,
          cantidad: 0,
        },
        {
          dia: "Sábado",
          DiaSemana: 7,
          cantidad: 0,
        },
        {
          dia: "Domingo",
          DiaSemana: 1,
          cantidad: 0,
        },
      ],
      ultimasrows: [],
      siguientesrows: [],
      categoriaId: 0,
      bannerMarketingShow: false,
      bannerSeleccionado: null,
      bannerEdicionesConfiguradas: [],
      banners: [],
      cantidadCarrito: 0,
      verCaracteristicasProductoNoEditorial: false,
      productoNoEditorialNombre: "",
      productoNoEditorialPrecioVenta: 0,
      productoNoEditorialCaracteristicas: "",
      edicioncodigoEditor: null,
      carritoVirtual: [],
      incentivoHabilitado: false,
      canjeHabilitado: false,
      timerId: [],
      ordenamiento: [],
      orden: { value: 1, label: "" },
    };
  }

  static contextType = AppContext;

  history = createBrowserHistory();

  validarVencimientoToken(mensaje) {
    if (mensaje) {
      if (
        mensaje === "Authorization has been denied for this request." ||
        mensaje.indexOf("Unexpected token") !== -1
      ) {
        localStorage.removeItem("ddr-auth");
        localStorage.removeItem("ddr-token");
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("infoToken");
        // localStorage.removeItem('refresh-token');
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
        window.location.reload();
        return;
      }
    }
    return;
  }

  handleOrden = (event) => {
    this.setState({ orden: event.target.value });
  };

  actualizarPermisos = (versionDB) => {
    var infoToken = JSON.parse(localStorage.infoToken);
    var data = {
      usuarioNombre: infoToken.name,
      ambitoId: 7,
      entidadId: infoToken.entidad_id,
      permisoNombre: "kiosco",
    };
    var url = urlServer + "/api/account/permisos/kiosco/buscar";
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((permisos) => {
        infoToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] = [...permisos];
        localStorage.setItem("infoToken", JSON.stringify(infoToken));
        localStorage.setItem("versionLocal", versionDB);

        MySwal.fire({
          icon: "info",
          title:
            "Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.",
          showConfirmButton: false,
          timer: 2000,
          customClass: "swalIconCustom",
        })
          .then((result) => {
            if (result.value) {
              this.setState({ loading: true });
              window.location.reload();
            } else {
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log("buscar permisos > error :", error);
      });
  };

  versionResquest = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const url = urlServer + "/api/app/version";
    //la api no requiere permiso
    const respuesta = await fetch(url, {
      method: "POST",
      redirect: "manual",      
      headers: headers,
    })
      .then((response) => response.json())
      .then((result) => {
        var versionDB = 0;
        if (typeof result !== "undefined" && result.length > 0) {
          versionDB = parseInt(result[0].versionValor, 10);
        }

        if (
          versionDB > versionActual &&
          (versionDB > localStorage.versionLocal ||
            localStorage.versionLocal === undefined)
        ) {
          this.actualizarPermisos(versionDB);
        }
      })
      .catch((error) => {
        console.log("versionResquest > error :", error);
      });

    return respuesta;
  };

  actualizarPuntos = (puntos) => {
    this.setState({
      puntos: this.state.puntos - puntos,
    });
  };
  // Sola muestra si tiene los permisos de canje
  mostrarCanje = () => {
    var result = "none";
    if (this.state.incentivoHabilitado) {
      result = "";
    }
    return result;
  };

  mostrarOpcionCanje = () => {
    const Links = JSON.parse(localStorage.infoToken)[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

    var result = "none";
    if (typeof Links !== "undefined") {
      Links.forEach((element) => {
        if (
          element === "KIOSCO.ClubParadaOnline" &&
            (this.state.incentivoHabilitado || this.state.canjeHabilitado )
        ) {
          result = "";
        }
      });
    }

    return result;
  };

  dataTienda = async (crearStockInicial = false) => {
    var menuProdMasVendido = null;

    ReactGA.event({
      category: "Tienda/CargarPedido",
      action: "Mostrar Catalogo",
    });

        this.setState({
            loading: true
        })
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token
        }

    const data = {
      nombreTienda: "Tienda Agea",
    };

    const url = urlServer + "/api/tienda/catalogoTienda/listarCompleto";

    const respuesta = await fetch(url, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => response.json())
      .then((resultadoCompleto) => {
        this.validarVencimientoToken(resultadoCompleto.message);
        this.setState({
          producto: resultadoCompleto.tiendaCatalogoEdiciones,
          resultPublicacionCategoria: resultadoCompleto.publicacionCategorias,
          resultTiendaCatProdMasVendido:
            resultadoCompleto.tiendaCatProdMasVendidos,
          loading: true,
        });
        menuProdMasVendido = resultadoCompleto.tiendaCatProdMasVendidos;

        this.listadoCategorias();
        // Recuperar ediciones del carrito
        var urlCarrito = urlServer + "/api/tienda/carrito/obtener";
        var dataCarrito = {
          UsuarioId: null,
        };

        fetch(urlCarrito, {
          method: "POST",
          body: JSON.stringify(dataCarrito),
          headers: {
            Authorization: "bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        })
          .then((responseCarrito) => {
            return responseCarrito.json();
          })
          .then((resultCarrito) => {
            this.validarVencimientoToken(resultCarrito.message);
            // Recorro catalogo y precargo carrito
            const dataCatalogo = resultadoCompleto.tiendaCatalogoEdiciones.map(
              (edicion, index) => {
                edicion = {
                  ...edicion,
                  excedido: 0,
                  marcaExcedido: false,
                  stockInicial: edicion.stockDisponibleAlmacen,
                  cantidadBackUp: edicion.cantidad,
                };

                var indice = resultCarrito.findIndex(
                  (ed) =>
                    ed.edicionId === edicion.edicionId &&
                    ed.utilizaPunto === false
                );
                if (indice !== -1) {
                  var aux = resultCarrito[indice];
                  edicion.cantidad = aux.cantidad;
                  edicion.stockInicial =
                    edicion.cantidad + edicion.stockDisponibleAlmacen;
                }

                if (!edicion.cantidad) {
                  edicion.cantidad = 0;
                }

                return edicion;
              }
            );
            this.setState({
              backendrows: JSON.parse(JSON.stringify(dataCatalogo)),
              loading: false,
              cantidadCarrito: resultCarrito.length,
              carritoVirtual: JSON.parse(JSON.stringify(resultCarrito)),
            });
          })
          .catch((errorCarrito) => {
            this.validarVencimientoToken(errorCarrito.message);
            console.log("error al precargar carrito", errorCarrito);
          });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
        this.setState({
          loading: false,
        });
      });

    if (menuProdMasVendido) {
      localStorage.setItem(
        "menu-ProdMasVendido",
        JSON.stringify(menuProdMasVendido)
      );
    }

    return respuesta;
  };

  // inicio funciones para input

  calcularExcedentePedido = (edicion) => {
    return Math.abs(edicion.stockInicial - edicion.cantidad);
  };

  mostrarExcesoStock = (edicion) => {    
    return edicion.stockInicial - edicion.cantidad < 0 ? "inline" : "none";
  };

  // tiendaOCanje === true viene de tienda, tiendaOCanje === false viene de canje,
  definirBackGround = (edicion) => {
    var color = "white";
    if (edicion.stockDisponibleAlmacen < 0 || edicion.cantidad === "empty") {
      color = "hsla(14, 100%, 53%, 0.5)";
    }

    return color;
  };

  checkearEdicionEnCarrito = (edicion) => {
    const { carritoVirtual } = this.state;
    // Para incrementar el numero del carrito, checkeo si la edicion a la que estoy aumentando se encuentra en el carrito.
    let ind = carritoVirtual.findIndex(
      (e) => e.edicionId === edicion.edicionId && !e.utilizaPunto
    );
    if (ind === -1 && edicion.cantidad > 0) {
      carritoVirtual.push(edicion);
      this.setState({
        cantidadCarrito:
          edicion.cantidad === 0
            ? this.state.cantidadCarrito
            : this.state.cantidadCarrito + 1,
        carritoVirtual,
      });
    } else if (edicion.cantidad === 0 && ind !== -1) {
      carritoVirtual.splice(ind, 1);
      this.setState({
        cantidadCarrito:
          edicion.cantidad === 0
            ? this.state.cantidadCarrito - 1
            : this.state.cantidadCarrito,
        carritoVirtual,
      });
    }
  };
  // fin funciones para input

  borrarCantidadCarrito = () => {
    this.dataTienda();
  };

  actualizarNumeroCarrito = (cantidadCarrito) => {
    this.setState({ cantidadCarrito: cantidadCarrito });
  };

  bannerEdicionesConfiguradas = (bannerId) => {
    ReactGA.event({
      category: "Tienda/CargarPedido",
      action: "Obtener Ediciones Configuradas por Banner",
    });

    const url = urlServer + "/api/tienda/catalogoTienda/listar";
    const data = {
      nombreTienda: "Tienda Agea",
      bannerId: bannerId,
    };

    const respuesta = fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        var ediciones = [];
        result.forEach(function (value, index, array) {
          ediciones.push(value.edicionId);
        });

        this.setState({ bannerEdicionesConfiguradas: ediciones });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error al obtener ediciones configuradas ", error);
      });

    return respuesta;
  };

  listadoCategorias = async () => {
    try {
      var resPubCategoria = this.state.resultPublicacionCategoria;
      if (resPubCategoria) {
        if (resPubCategoria?.filter) {
          var categoriasSinDestacados = resPubCategoria.filter(
            (x) => x.descripcion !== "DESTACADOS"
          );

          var aux = resPubCategoria.filter(
            (x) => x.descripcion === "DESTACADOS"
          );
          var categoriaId = null;
          if (aux.length > 0) {
            categoriaId = aux[0].publicacionCategoriaId;
          }

          this.setState({ categoriaId: categoriaId });
          this.setState({ publicationCategoria: categoriasSinDestacados });

          // Cuando selecciono publicacion desde menu lateral ------------------

          let urlConParametros = this.context.urlConParametros;
          if (urlConParametros.includes("?")) {
            let url = new URL(urlConParametros);
            let categoria = url.searchParams.get("categoria");
            let producto = url.searchParams.get("producto");
            if (categoria.toLowerCase() === "todos") {
              this.setState({ flagCatalogoCategoria: false });
              if (producto && producto !== "undefined") {
                this.setState({ busqueda: producto });
                this.setState({ busquedaPorCategoria: producto });
                this.setState({ busquedaTextoMostrarEnInput: producto });
              }
              this.props.hideMenu(true);
            } else {
              if (categoria) {
                this.setState({ flagCatalogoCategoria: true });
                categoriasSinDestacados.map((card, index) => {
                  if (
                    card.descripcion.toLowerCase() === categoria.toLowerCase()
                  ) {
                    this.setState({ categoriaSeleccionada: card });
                  }
                });
              }
              if (producto) {
                this.setState({ busqueda: producto });
                this.setState({ busquedaPorCategoria: producto });
                this.setState({ busquedaTextoMostrarEnInput: producto });
              }
              this.props.hideMenu(true);
            }
            this.context.urlConParametros = "";
          }
        }
      }
    } catch (error) {
      this.validarVencimientoToken(error.message);
      console.log("error", error);
    }

    // return respuesta;
  };

  compartir = async (edicion) => {
    fetch(`${tiendaUrl}/${edicion.codigoEditor}.png`)
      .then(function (response) {
        if (response.ok) {
          response.blob().then((blobData) => {
            var file = new File([blobData], "picture.png", {
              type: "image/png",
            });
            var filesArray = [file];
            if (
              navigator.canShare &&
              navigator.canShare({ files: filesArray })
            ) {
              navigator
                .share({
                  text: `Publicacion: ${edicion.edicionDescripcionCorta} \n Precio de Venta: $${edicion.precioTapa}`,
                  files: filesArray,
                  title: "Compartir publicacion",
                })
                .then(() => console.log("Successful share"))
                .catch((error) => console.log("Error sharing:", error));
            }
          });
        }
      })
      .catch(function (response) {
        fetch(`${tienda}`)
          .then((response) => response.blob())
          .then((blobData) => {
            var file = new File([blobData], "picture.png", {
              type: "image/png",
            });
            var filesArray = [file];
            if (
              navigator.canShare &&
              navigator.canShare({ files: filesArray })
            ) {
              navigator
                .share({
                  text: `Publicacion: ${edicion.edicionDescripcionCorta} \n Precio de Venta: $${edicion.precioTapa}`,
                  files: filesArray,
                  title: "Compartir publicacion",
                })
                .then(() => console.log("Successful share imagen default"))
                .catch((error) =>
                  console.log("Error sharing imagen default:", error)
                );
            }
          });
      });
  };

  puntos = async () => {
    this.setState({
      loading: true,
    });

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.token,
    };

    const data = {
      nombreTienda: "Tienda Agea",
      puntoVentaId: null,
    };

    const url = urlServer + "/api/incentivo/puntos/obtener";
    const respuesta = await fetch(url, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(data),
      headers,
    })
      .then((response) => response.json())
      .then((resultPuntos) => {
        this.validarVencimientoToken(resultPuntos.message);

        var disponible = 0;
        var diasRestantes = 0;
        var darAviso = 0;

        if (typeof resultPuntos[0] !== "undefined") {
          disponible = resultPuntos[0].disponible;
          diasRestantes = resultPuntos[0].diasRestantes;
          darAviso = resultPuntos[0].darAviso;
        }

        this.setState({
          puntos: disponible,
          diasRestantes: diasRestantes,
          darAviso: darAviso,
          loading: false,
        });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
        this.setState({
          loading: false,
        });
      });
    return respuesta;
  };

  componentDidMount() {
    if (localStorage.getItem("flagRecargarTienda")) {
      localStorage.removeItem("flagRecargarTienda");
      window.location.reload(true);
    }

    if (!this.state.backendrows || this.state.backendrows.length < 1) {
      this.dataTienda(true);
      this.puntos();
    }
    document.title = "Productos";
    this.obtenerBanners();
    this.obtenerConfiguracionIncentivoHabilitado();
    this.obtenerConfiguracionCanjeHabilitado();

    // Este codigo se usa al volver de canje de puntos para setear la pantalla a como estaba previamente
    if (localStorage.getItem("flagCatalogoCategoria")) {
      this.setState({
        flagCatalogoCategoria:
          localStorage.getItem("flagCatalogoCategoria") === "true",
      });
      this.setState({
        bannerMarketingShow:
          localStorage.getItem("bannerMarketingShow") === "true",
      });
      var auxBanner = {
        bannerId:
          localStorage.getItem("bannerSeleccionado.bannerId") !== "null"
            ? parseInt(localStorage.getItem("bannerSeleccionado.bannerId"))
            : null,
        descripcion:
          localStorage.getItem("bannerSeleccionado.descripcion") !== "null"
            ? localStorage.getItem("bannerSeleccionado.descripcion")
            : null,
      };
      this.setState({ bannerSeleccionado: auxBanner });
      if (auxBanner.bannerId !== null) {
        this.bannerEdicionesConfiguradas(auxBanner.bannerId);
        this.props.hideMenu(false);
      }

      if (
        localStorage.getItem("categoriaSeleccionada.publicacionCategoriaId") !==
        "null"
      ) {
        var auxCategoria = {
          publicacionCategoriaId: parseInt(
            localStorage.getItem("categoriaSeleccionada.publicacionCategoriaId")
          ),
          descripcion: localStorage.getItem(
            "categoriaSeleccionada.descripcion"
          ),
        };

        this.setState({ categoriaSeleccionada: auxCategoria });
        this.props.hideMenu(false);
      }

      localStorage.removeItem("flagCatalogoCategoria");
      localStorage.removeItem("categoriaSeleccionada.publicacionCategoriaId");
      localStorage.removeItem("categoriaSeleccionada.descripcion");
      localStorage.removeItem("bannerMarketingShow");
      localStorage.removeItem("bannerSeleccionado.descripcion");
      localStorage.removeItem("bannerSeleccionado.bannerId");
    }
  }

  tipoOrdenamientoObtener = async (string) => {
    const url = urlServer + "/api/tienda/ordenamientotipo/listar";
    const respuesta = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          orden: { value: result[0].codigo, label: result[0].descripcion },
        });
        var opciones = result.map((orden, index) => {
          return {
            value: orden.codigo,
            label: orden.descripcion,
          };
        });
        return opciones;
      })
      .catch((error) => {
        console.log(error);
      });
    return await respuesta;
  };

  preventScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  // Deshabilitar scroll grilla ediciones
  deshabilitarScroll = () => {
    if (this.state.verCaracteristicasProductoNoEditorial) {
      document
        .querySelector("#cargarpedidotienda")
        .addEventListener("wheel", this.preventScroll);
    }
  };

  obtenerConfiguracionIncentivoHabilitado = () => {
    var url =
      urlServer + "/api/configuracionItem/configuracion/buscarLigeroPorClave";
    var data = {
      clave: "Tienda.Incentivos.PedidoCompra.Habilitado",
      entidadId: null,
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        this.setState({
          incentivoHabilitado: result[0].valor === "1" ? true : false,
        });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(
          "error al obtener la configuracion de incentivo habilitado"
        );
      });
  };
  
    obtenerConfiguracionCanjeHabilitado = () => {
    var url =
      urlServer + "/api/configuracionItem/configuracion/buscarLigeroPorClave";
    var data = {
      clave: "Tienda.Canje.Habilitado",
      entidadId: null,
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        this.setState({
          canjeHabilitado: result[0].valor === "1" ? true : false,
        });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(
          "error al obtener la configuracion de incentivo habilitado"
        );
      });
  };

  obtenerBanners = () => {
    var url = urlServer + "/api/banner/buscar";

    fetch(url, {
      method: "POST",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        var nroBanners = result.length;

        if (nroBanners > 0) {
          this.setState({ banners: [...result] });
        }
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error " + error);
      });
  };

  getCantidadCarrito = () => {
    return this.state.cantidadCarrito;
  };

  redireccionar = () => {
    if (this.state.redirect) {
      if (this.state.successCC) {
        return (
          <Redirect
            push
            to={{
              pathname: "/Tienda/MisPedidos",
            }}
          />
        );
      }

      return (
        <Redirect
          push
          to={{
            pathname: this.state.seleccionadasFlag ? "/Tienda" : "/",
          }}
        />
      );
    }
  };

  addDefaultSrc(ev) {
    ev.target.src = tienda;
  }

  addDefaultBannerSrc(ev) {
    ev.target.src = `${tienda}`;
  }

  // Funciones para persistir carrito de compras
  tiendaCarritoActualizar = (edicionId, cantidad) => {
    var url = urlServer + "/api/tienda/carrito/actualizar";
    var data = {
      usuarioId: null,
      edicionId: edicionId,
      cantidad: cantidad,
      utilizaPunto: 0,
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  };

  cardItem = (edicion) => {
    return (
      <div
        className="w-100 d-flex justify-content-between"
        style={{ marginBottom: "10px" }}
      >
        <span className="subTitleItem" style={{ fontSize: "15px" }}>
          {edicion.edicionDescripcionCorta}
        </span>
        &nbsp;
        <div style={{ display: "", textAlign: "right" }}>
          <em
            className="priceItem"
            style={{
              display: "block",
              textDecoration:
                edicion.esInterior === false &&
                edicion.etiquetaDescuento.length > 0
                  ? "line-through"
                  : "",
              maxHeight: "18px",
              fontSize:
                edicion.esInterior === false &&
                edicion.etiquetaDescuento.length > 0
                  ? "13px "
                  : "15px",
              marginLeft: "20px",
              height:
                edicion.esInterior === false &&
                edicion.etiquetaDescuento.length > 0
                  ? "16px "
                  : "",
            }}
          >
            ${edicion.precioVenta.toFixed(2)}
          </em>
          {edicion.esInterior === false &&
          edicion.etiquetaDescuento.length > 0 ? (
            <React.Fragment>
              <div>
                <em
                  className="priceItem"
                  style={{
                    fontSize: "15px",
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ${edicion.precioConDescuento.toFixed(2)}
                </em>
              </div>
              <div>
                <em
                  className="priceItem"
                  style={{
                    width: "102px",
                    fontSize: "14px",
                    marginLeft: "0px",
                    color: "#09b909c7",
                    position: "relative",
                    whiteSpace: "nowrap",
                  }}
                >
                  {edicion.descuento}% OFF
                </em>
              </div>
            </React.Fragment>
          ) : (
            <div>
              {edicion.etiquetaDescuento.length > 0 ? (
                <React.Fragment>
                  <div>
                    <em
                      className="priceItem"
                      style={{
                        fontSize: "14px",
                        marginLeft: "0px",
                        color: "#09b909c7",
                        position: "relative",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {edicion.descuento}% OFF
                    </em>
                  </div>
                  <div>
                    <em
                      className="priceItem"
                      style={{
                        width: "102px",
                        fontSize: "14px",
                        marginLeft: "0px",
                        color: "#09b909c7",
                        position: "relative",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Costo Vendedor
                    </em>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          )}
          {edicion.puntos > 0 && this.viewCanjeNuevo(edicion)}
        </div>
      </div>
    );
  };

  handleClickCanje = (edicion) => {
    localStorage.setItem(
      "flagCatalogoCategoria",
      this.state.flagCatalogoCategoria
    );
    localStorage.setItem("flagVolverDeCanje", "flagVolverDeCanje");
    localStorage.setItem(
      "categoriaSeleccionada.publicacionCategoriaId",
      this.state.categoriaSeleccionada !== null
        ? this.state.categoriaSeleccionada.publicacionCategoriaId
        : null
    );
    localStorage.setItem(
      "categoriaSeleccionada.descripcion",
      this.state.categoriaSeleccionada !== null
        ? this.state.categoriaSeleccionada.descripcion
        : null
    );
    localStorage.setItem("bannerMarketingShow", this.state.bannerMarketingShow);
    localStorage.setItem(
      "bannerSeleccionado.descripcion",
      this.state.bannerSeleccionado !== null
        ? this.state.bannerSeleccionado.descripcion
        : null
    );
    localStorage.setItem(
      "bannerSeleccionado.bannerId",
      this.state.bannerSeleccionado !== null
        ? this.state.bannerSeleccionado.bannerId
        : null
    );

    this.props.props.history.push({
      pathname: "/ClubParadaOnline",
      params: {
        buscar: edicion.edicionDescripcionCorta,
      },
    });
  };

  viewCanje = (edicion) => {
    return edicion.puntos > 0 ? (
      <div style={{ display: "flex" }}>
        <figcaption
          style={{ display: this.mostrarOpcionCanje(), cursor: "pointer" }}
          className="titleClickPuntoItem"
          onClick={() => {
            localStorage.setItem(
              "flagCatalogoCategoria",
              this.state.flagCatalogoCategoria
            );
            localStorage.setItem("flagVolverDeCanje", "flagVolverDeCanje");
            localStorage.setItem(
              "categoriaSeleccionada.publicacionCategoriaId",
              this.state.categoriaSeleccionada !== null
                ? this.state.categoriaSeleccionada.publicacionCategoriaId
                : null
            );
            localStorage.setItem(
              "categoriaSeleccionada.descripcion",
              this.state.categoriaSeleccionada !== null
                ? this.state.categoriaSeleccionada.descripcion
                : null
            );
            localStorage.setItem(
              "bannerMarketingShow",
              this.state.bannerMarketingShow
            );
            localStorage.setItem(
              "bannerSeleccionado.descripcion",
              this.state.bannerSeleccionado !== null
                ? this.state.bannerSeleccionado.descripcion
                : null
            );
            localStorage.setItem(
              "bannerSeleccionado.bannerId",
              this.state.bannerSeleccionado !== null
                ? this.state.bannerSeleccionado.bannerId
                : null
            );

            this.props.props.history.push({
              pathname: "/ClubParadaOnline",
              params: {
                buscar: edicion.edicionDescripcionCorta,
              },
            });
          }}
        >
          Canjear por Puntos
        </figcaption>
        <figcaption
          style={{ display: this.mostrarOpcionCanje() }}
          className="titlePuntoItem"
        >
          {edicion.puntos} Puntos
        </figcaption>
      </div>
    ) : null;
  };

  viewCanjeNuevo = (edicion) => {
    return (
      <div
        style={{
          display: this.mostrarOpcionCanje() === "" ? "flex" : "none",
          flexDirection: this.mostrarOpcionCanje() === "" ? "column" : "",
        }}
      >
        <span
          className="text-right"
          style={{
            whiteSpace: "nowrap",
            cursor: "pointer",
            justifyContent: "right",
          }}
        >
          o {edicion.puntos} {edicion.puntos === 1 ? 'punto' : 'puntos'} 
        </span>
        <span
          className="text-right"
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => this.handleClickCanje(edicion)}
        >
          Canjear
        </span>
      </div>
    );
  };

  verCarrito = () => {
    if (
      this.state.cantidadCarrito > 0 ||
      this.state.diasSemana.filter((e) => e.cantidad > 0).length > 0
    ) {
      this.setState({
        seleccionadasFlag: !this.state.seleccionadasFlag,
      });
      this.props.hideMenu(true);
      if (!this.state.seleccionadasFlag) {
        ReactGA.event({
          category: "Tienda/CargarPedido",
          action: "Ver Carrito",
        });
      } else {
        this.volverDeCarrito(this.state.seleccionadasFlag, {});
      }
    }
  };

  showAviso = () => {
    const { darAviso } = this.state;

    return darAviso ? "flex" : "none";
  };

  volverDeCarrito = (seleccionadasFlag, listado) => {
    this.dataTienda();
    this.puntos();
    this.setState({
      seleccionadasFlag: !seleccionadasFlag,
    });
    this.props.hideMenu(true);
  };

  esOfertaConPromo = (edicion) => {
    return edicion.etiquetaDescuento.length > 0 && edicion.tagPuntosPromocionDescripcion != null
          ? true 
          : false;
  };

  redondearPrecio = (numero) => {
    return numero > 0 ? numero.toFixed(2) : 0;
  };

  calcularMargin = (edicion) => {
      
      const m = -25 / 13; // Pendiente calculada
      const b = 48.08; // Intersección con el eje vertical calculada
      var pixeles = 0;
       // Tamaño de la fuente raíz (rem), por defecto es 16
      const tamanoFuenteRaiz = 16;
    
      if(edicion.tagPuntosPromocionDescripcion.length > 0) {
        pixeles = m * edicion.tagPuntosPromocionDescripcion.length + b;
      }
      // Convertir de píxeles a rem
      const rem = pixeles / tamanoFuenteRaiz;
      return "0px 0px 0px "+rem+"rem";
  };
  
  changeOrden = (e) => {
    this.setState({ orden: e });
    return e;
  };

  render() {
    const {
      loading,
      producto,
      seleccionadasFlag,
      backendrows,
      publicationCategoria,
      orden,
    } = this.state;

    if (!this.props.props.location.state) {
      return <Redirect to="/Tienda" />;
    }

    return (
      <div id="cargarpedidotienda">
        {!this.state.seleccionadasFlag ? null : (
          <div
            id="backarrow"
            className="position-fixed back-arrow-box"
            onClick={() => {
              this.volverDeCarrito(this.state.seleccionadasFlag, {});
              this.props.hideMenu(false);
            }}
          >
            <ReactSVG src={backArrow} />
          </div>
        )}

        {/* Flecha volver a categorias */}
        {this.state.categoriaSeleccionada &&
        this.state.flagCatalogoCategoria &&
        !this.state.seleccionadasFlag &&
        !this.state.bannerMarketingShow ? (
          <div
            id="backarrow"
            className="position-fixed back-arrow-box"
            onClick={() => {
              this.setState({ categoriaSeleccionada: null });
              this.props.hideMenu(false);
            }}
          >
            <ReactSVG src={backArrow} />
          </div>
        ) : null}

        {/* Flecha volver de ediciones banner */}
        {this.state.bannerMarketingShow ? (
          <div
            id="backarrow"
            className="position-fixed back-arrow-box"
            onClick={() => {
              this.setState({ bannerMarketingShow: false });
              this.props.hideMenu(false);
              this.setState({ busquedaPorEdicionBanner: "" });
            }}
          >
            <ReactSVG src={backArrow} />
          </div>
        ) : null}

        <div id="cargarpedido" className="tienda container text-left">
          <div className="d-flex justify-content-between">
            <Title
              classes=""
              title={
                seleccionadasFlag ? "Ediciones seleccionadas" : "Productos"
              }
            />
            {/*BOTON CARRITO SUPERIOR*/}
            <div
              className="position-relative"
              style={{ marginTop: "33px" }}
            >
              <ReactSVG
                src={pedidos}
                style={{ width: "27px", height: "27px", color: "#8E95A5" }}
              />
              <div
                className="position-absolute d-flex justify-content-center align-items-center f-11"
                style={{
                  right: "-8px",
                  top: "-8px",
                  background: "#EA3F3F",
                  color: "white",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                }}
              >
                {this.getCantidadCarrito()}
              </div>
            </div>
          </div>
          <div className="titulo-producto">{producto.descripcion}</div>

          {loading ? (
            <Spinner style={{ fontSize: "8px" }} />
          ) : (
            <React.Fragment>
              {seleccionadasFlag ? (
                <div style={{ paddingBottom: "95px" }}>
                  {/* Carrito */}
                  <Carrito
                    volverDeCarrito={this.volverDeCarrito}
                    borrarCantidadCarrito={this.borrarCantidadCarrito}
                    actualizarPuntos={this.actualizarPuntos}
                    actualizarNumeroCarrito={this.actualizarNumeroCarrito}
                    puntos={this.state.puntos}
                    props={this.props.props}
                  />
                </div>
              ) : this.state.producto.publicacionTipoId !== 2 ? (
                <div
                  className="pedido"
                  style={{ paddingBottom: "25px", marginBottom: "80px" }}
                >
                  {" "}
                  {/*padding con destacados*/}
                  <div
                    style={{
                      display: this.mostrarOpcionCanje(),
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                      paddingBottom: "5px",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "center",
                      color: "#224372",
                    }}
                  >
                    <span>
                      Puntos: {this.state.puntos <= 0 ? 0 : this.state.puntos}
                    </span>
                    <div
                      className="alerta-puntos"
                      style={{ display: this.showAviso() }}
                    >
                      <span>
                        Tus puntos se venceran en {this.state.diasRestantes}{" "}
                        {this.state.diasRestantes === 1 ? "dia" : "dias"}
                      </span>
                    </div>
                  </div>
                  {/* Campo de busqueda, para categorias y todas las publicaciones */}
                  {!this.state.bannerMarketingShow ? (
                    <div className="w-100" style={{ marginBottom: "-20px" }}>
                      <input
                        className="w-100 form-control"
                        type="text"
                        placeholder="Buscar"
                        onChange={(e) => {
                          this.setState({
                            busquedaPorCategoria: e.target.value
                              .normalize("NFD")
                              .replace(/\p{Diacritic}/gu, ""),
                              // .replace(/\./g, ""),
                            busqueda: e.target.value
                              .normalize("NFD")
                              .replace(/\p{Diacritic}/gu, ""),
                              // .replace(/\./g, ""),
                            busquedaTextoMostrarEnInput: e.target.value,
                          });
                        }}
                        onKeyUp={() => {
                          if (
                            this.state.flagCatalogoCategoria &&
                            !this.state.categoriaSeleccionada
                          ) {
                            this.state.flagCatalogoCategoria = false;
                            this.setState({
                              flagCatalogoCategoria:
                                this.state.flagCatalogoCategoria,
                            });
                          }
                        }}
                        value={this.state.busquedaTextoMostrarEnInput}
                      />
                    </div>
                  ) : null}
                  {/* Campo de busqueda, para ediciones de banner configuradas*/}
                  {this.state.bannerMarketingShow ? (
                    <div className="w-100" style={{ marginBottom: "-20px" }}>
                      <input
                        className="w-100 form-control"
                        type="text"
                        placeholder="Buscar"
                        onChange={(e) => {
                          this.setState({
                            busquedaPorEdicionBanner: e.target.value,
                          });
                        }}
                        value={this.state.busquedaPorEdicionBanner}
                      />
                    </div>
                  ) : null}
                  {/* Combo para ordenar */}
                  <div className="d-flex justify-content-end align-items-center">
                    <div style={{ fontSize: "14px", paddingTop: "15px" }}>
                      Orden:&nbsp;
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        width: "170px",
                        zIndex: "1005",
                      }}
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={this.tipoOrdenamientoObtener}
                        defaultOptions
                        onChange={this.changeOrden}
                        value={this.state.orden}
                      />
                    </div>
                  </div>
                  {/* Fin Combo para ordenar */}
                  {/* Pestañas catalogo y todas las categorias */}
                  {!this.state.bannerMarketingShow ? (
                    <div
                      className="tabs d-flex justify-content-between w-100 "
                      style={{
                        borderBottom: "1px solid gray",
                        marginBottom: "18px",
                      }}
                    >
                      <div
                        className={
                          "tablinks col-6 text-center" +
                          (this.state.flagCatalogoCategoria ? " active" : "")
                        }
                        onClick={(e) => {
                          this.setState({ flagCatalogoCategoria: true });
                          if (this.state.categoriaSeleccionada) {
                            this.props.hideMenu(true);
                          }
                        }}
                      >
                        Categorías
                      </div>
                      <div
                        className={
                          "tablinks col-6 text-center" +
                          (!this.state.flagCatalogoCategoria ? " active" : "")
                        }
                        onClick={(e) => {
                          this.setState({ flagCatalogoCategoria: false });
                          this.props.hideMenu(false);
                        }}
                      >
                        Todos los productos
                      </div>
                    </div>
                  ) : null}
                  <div>
                    {/* Listado de categorias */}
                    {this.state.flagCatalogoCategoria &&
                    !this.state.categoriaSeleccionada &&
                    !this.state.bannerMarketingShow ? (
                      <div className="w-100" style={{ marginBottom: "-85px" }}>
                        <div
                          style={{
                            paddingBottom: "0px", // padding con seccion destacados
                            marginTop: "0px",
                            display: "grid",
                            gridGap: "8px",
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(98px, 1fr))",
                            justifyItems: "center",
                          }}
                        >
                          {publicationCategoria.length > 0
                            ? publicationCategoria.map((card, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="categoria d-flex justify-content-center align-items-center"
                                    onClick={async () => {
                                      this.setState({
                                        categoriaSeleccionada: card,
                                      });
                                      this.props.hideMenu(true);
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center align-items-center text-center"
                                      style={{
                                        color: "white",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {card.descripcion}
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    ) : null}

                    {/* Nombre categoria */}
                    {this.state.categoriaSeleccionada &&
                    this.state.flagCatalogoCategoria &&
                    !this.state.bannerMarketingShow ? (
                      <div className="w-100 d-flex justify-content-center align-items-center text-center">
                        <div
                          style={{
                            color: "#5775AA",
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginBottom: "10px",
                          }}
                        >
                          Categoria:{" "}
                          {this.state.categoriaSeleccionada.descripcion}
                        </div>
                      </div>
                    ) : null}

                    {/* Catalogo con categoria */}
                    {/* .normalize('NFD').replace(/\p{Diacritic}/gu, "") quita las letras con acento y las reemplaza por letras sin. */}
                    {this.state.categoriaSeleccionada !== null
                      ? backendrows
                          .filter((e) =>
                            this.state.flagCatalogoCategoria &&
                            this.state.categoriaSeleccionada
                              ? e.edicionId > 0
                              : typeof e.edicionId !== "number"
                          )
                          .filter(
                            (a) =>
                              JSON.stringify(Object.values(a))
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                                .toLowerCase()
                                .indexOf(
                                  this.state.busquedaPorCategoria
                                    .normalize("NFD")
                                    .replace(/\p{Diacritic}/gu, "")
                                    .toLowerCase()
                                ) 
                                !== -1
                          )
                          .filter(
                            (e) =>
                              (this.state.categoriaSeleccionada.descripcion ===
                                "MAS VENDIDOS" &&
                                e.tagMasVendido === "MAS VENDIDO") ||
                              (this.state.categoriaSeleccionada.descripcion !==
                                "MAS VENDIDOS" &&
                                e.productoGrupoIds !== null &&
                                e.productoGrupoIds
                                  .match(/\d+/g)
                                  .map(Number)
                                  .includes(
                                    this.state.categoriaSeleccionada
                                      .publicacionCategoriaId
                                  ))
                          )
                          .sort(function (a, b) {
                            if (orden.value === 1) {
                              return a.ordenAlfabetico - b.ordenAlfabetico;
                            } else if (orden.value === 2) {
                              return a.ordenMenorPrecio - b.ordenMenorPrecio;
                            } else if (orden.value === 3) {
                              return a.ordenMayorPrecio - b.ordenMayorPrecio;
                            } else if (orden.value === 4) {
                              return (
                                a.ordenUltimosSubidos - b.ordenUltimosSubidos
                              );
                            } else if (orden.value === 5) {
                              return (
                                a.ordenMenosVendidos - b.ordenMenosVendidos
                              );
                            } else if (orden.value === 6) {
                              return a.ordenMasVendidos - b.ordenMasVendidos;
                            }
                          })
                          .map((edicion, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between days itemTienda"
                              >
                                <LazyLoad style={{ height: "100%" }}>
                                  <div className="whatsapp-share-button">
                                    <ReactSVG
                                      src={compartir}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.compartir(edicion)}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                    }}
                                  >
                                    <figure>
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <div className="tags-container">
                                          {edicion.tagPuntosPromocionDescripcion ? (
                                            <div className="constainerMultiplicaPuntosItem">
                                              <figcaption className="titleTagMultiplicaPuntosItem"
                                                style={{
                                                  margin: this.calcularMargin(edicion)
                                                }} >
                                                {edicion.tagPuntosPromocionDescripcion}
                                              </figcaption>{" "}
                                            </div>
                                          ) : null}
                                          {edicion.etiquetaDescuento.length > 0 ? (
                                            <div>
                                            {this.esOfertaConPromo(edicion) ? 
                                            <figcaption className="titleTagDescuentoItem"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            :
                                            <figcaption className="titleTagDescuentoItemSinPromo"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            }
                                            
                                          </div>
                                          ) : null}
                                        </div>
                                        <img
                                          src={
                                            edicion.imagen
                                              ? `data:image/jpeg;base64,${edicion.imagen}`
                                              : `${tiendaUrl}/${edicion.codigoEditor}.png`
                                          }
                                          onError={this.addDefaultSrc}
                                          style={{ maxWidth: "150px" }}
                                          alt="placeholder"
                                        />
                                        {edicion.tagMasVendido !== "" ? (
                                          <div className="titleTagItem-container">
                                            <figcaption className="titleTagItem">
                                              {edicion.tagMasVendido}
                                            </figcaption>{" "}
                                          </div>
                                        ) : null}
                                        {edicion.esPreventa === true ? (
                                          <div className="titleTagPreVentaItem-container">
                                            <figcaption className="titleTagPreVentaItem">
                                              Preventa
                                            </figcaption>{" "}
                                          </div>
                                        ) : null}
                                      </div>
                                    </figure>

                                    <div
                                      style={{
                                        marginTop: "5px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%",
                                      }}
                                    >
                                      {this.cardItem(edicion)}

                                      {edicion.edicionProductoNoEditorialId !==
                                      null ? (
                                        <div
                                          className="producto-no-editorial-ver-caracteristicas"
                                          onClick={() => {
                                            this.setState({
                                              productoNoEditorialNombre:
                                                edicion.edicionDescripcionCorta,
                                            });
                                            this.setState({
                                              productoNoEditorialCaracteristicas:
                                                edicion.caracteristicasProducto,
                                            });
                                            this.setState({
                                              productoNoEditorialPrecioVenta:
                                                edicion.precioVenta,
                                            });
                                            this.setState({
                                              cantidadImagenes:
                                                edicion.cantidadImagenes,
                                            });
                                            this.setState({
                                              edicioncodigoEditor:
                                                edicion.codigoEditor,
                                            });
                                            this.setState({
                                              verCaracteristicasProductoNoEditorial: true,
                                            });
                                          }}
                                        >
                                          {" "}
                                          <a>
                                            Ver Características del producto
                                          </a>
                                        </div>
                                      ) : null}

                                      <div className="w-100 footerItem align-items-center d-flex justify-content-between">
                                        <div
                                          className="f-14 fw-500"
                                          style={{
                                            maxWidth: "66%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <span>
                                            Stock disponible:{" "}
                                            {edicion.stockDisponibleAlmacen < 0
                                              ? 0
                                              : edicion.stockDisponibleAlmacen}
                                          </span>{" "}
                                          <br />
                                          <div
                                            id={`excesoStock-${edicion.edicionId}`}
                                            style={{
                                              display:
                                                this.mostrarExcesoStock(
                                                  edicion
                                                ),
                                              color: "red",
                                            }}
                                          >
                                            El pedido sobrepasa el stock por{" "}
                                            {this.calcularExcedentePedido(
                                              edicion
                                            )}{" "}
                                            ejemplar/es
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                          <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              cursor: "pointer",
                                              background: !edicion.cantidad
                                                ? "#FCFCFC"
                                                : "#F4F4F4",
                                              width: "26px",
                                              height: "26px",
                                              borderRadius: "50%",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              if (edicion.cantidad > 0) {
                                                edicion.cantidad--;
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockDisponibleAlmacen +
                                                  1;
                                                edicion.cantidadBackup =
                                                  edicion.cantidad;
                                                if (
                                                  edicion.stockDisponibleAlmacen >=
                                                  0
                                                ) {
                                                  this.checkearEdicionEnCarrito(
                                                    edicion
                                                  );
                                                  this.tiendaCarritoActualizar(
                                                    edicion.edicionId,
                                                    edicion.cantidad
                                                  );
                                                }
                                                let { backendrows } =
                                                  this.state;
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }
                                            }}
                                          >
                                            <ReactSVG
                                              src={restar}
                                              style={{
                                                color: !edicion.cantidad
                                                  ? "#EAEAEA"
                                                  : "#8E95A5",
                                                width: "11px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="f-13-5"
                                            style={{
                                              marginLeft: "10px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <input
                                              style={{
                                                backgroundColor:
                                                  this.definirBackGround(
                                                    edicion
                                                  ),
                                                border: "#343435 solid 1px",
                                              }}
                                              type="number"
                                              className="input-of-cantidades text-center"
                                              onInput={(e) =>
                                                (e.target.value =
                                                  e.target.value.slice(0, 5))
                                              }
                                              min="0"
                                              max="99999"
                                              maxLength="5"
                                              id={`tienda-${edicion.edicionId}`}
                                              value={edicion.cantidad}
                                              autocomplete="off"
                                              onFocus={(e) => {
                                                e.target.select();
                                              }}
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  e.currentTarget.blur();
                                                }
                                              }}
                                              onBlur={(e) => {
                                                let { backendrows } =
                                                  this.state;
                                                let regex = /[^0-9]/g;
                                                if (
                                                  regex.test(e.target.value)
                                                ) {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      regex,
                                                      ""
                                                    );
                                                }
                                                edicion.cantidad =
                                                  e.target.value === "" ||
                                                  edicion.cantidad === "empty"
                                                    ? 0
                                                    : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockInicial -
                                                  (edicion.cantidad === "empty"
                                                    ? 0
                                                    : edicion.cantidad);
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                                if (
                                                  edicion.stockDisponibleAlmacen >=
                                                    0 &&
                                                  edicion.cantidadBackUp !==
                                                    edicion.cantidad
                                                ) {
                                                  edicion.cantidadBackUp =
                                                    edicion.cantidad;
                                                  this.checkearEdicionEnCarrito(
                                                    edicion
                                                  );
                                                  this.tiendaCarritoActualizar(
                                                    edicion.edicionId,
                                                    edicion.cantidad
                                                  );
                                                } else if (
                                                  edicion.stockDisponibleAlmacen <
                                                  0
                                                ) {
                                                  MySwal.fire({
                                                    icon: "error",
                                                    title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                    showConfirmButton: true,
                                                    confirmButtonText:
                                                      "Aceptar",
                                                  });
                                                }
                                              }}
                                              onChange={(e) => {
                                                let { backendrows } =
                                                  this.state;
                                                let regex = /[^0-9]/g;
                                                if (
                                                  regex.test(e.target.value)
                                                ) {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      regex,
                                                      ""
                                                    );
                                                }
                                                e.target.value =
                                                  e.target.value === "0"
                                                    ? e.target.value
                                                    : e.target.value.replace(
                                                        /^(0+)/g,
                                                        ""
                                                      );
                                                edicion.cantidad =
                                                  e.target.value === ""
                                                    ? "empty"
                                                    : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockInicial -
                                                  (edicion.cantidad === "empty"
                                                    ? 0
                                                    : edicion.cantidad);
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              cursor: "pointer",
                                              background:
                                                edicion.stockDisponibleAlmacen <=
                                                0
                                                  ? "#FCFCFC"
                                                  : "#F4F4F4",
                                              width: "26px",
                                              height: "26px",
                                              borderRadius: "50%",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              if (
                                                edicion.stockDisponibleAlmacen >
                                                0
                                              ) {
                                                edicion.cantidad++;
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockDisponibleAlmacen -
                                                  1;
                                                edicion.cantidadBackup =
                                                  edicion.cantidad;
                                                this.checkearEdicionEnCarrito(
                                                  edicion
                                                );
                                                this.tiendaCarritoActualizar(
                                                  edicion.edicionId,
                                                  edicion.cantidad
                                                );
                                                let { backendrows } =
                                                  this.state;
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }
                                            }}
                                          >
                                            <ReactSVG
                                              src={sumar}
                                              style={{
                                                color:
                                                  edicion.stockDisponibleAlmacen <=
                                                  0
                                                    ? "#EAEAEA"
                                                    : "#8E95A5",
                                                width: "11px",
                                                height: "18px",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            {/* Se agrego para que no seleccione la imagen al hacer doble click en sumar */}
                                            &nbsp;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </LazyLoad>
                              </div>
                            );
                          })
                      : null}

                    {/* Catalogo todas las publicaciones */}
                    {/* .normalize('NFD').replace(/\p{Diacritic}/gu, "") quita las letras con acento y las reemplaza por letras sin. */}
                    {backendrows
                      .filter((e) =>
                        !this.state.flagCatalogoCategoria
                          ? e.edicionId > 0
                          : typeof e.edicionId !== "number"
                      )
                      .filter(
                        (a) => {
                          // Copia del objeto sin la propiedad `productoGrupoDescripciones`
                          const aSinProductoGrupoDescripciones = { ...a };
                          delete aSinProductoGrupoDescripciones.productoGrupoDescripciones;
                          const strA = JSON.stringify(Object.values(aSinProductoGrupoDescripciones))
                            .normalize("NFD")
                            .replace(/\p{Diacritic}/gu, "")
                            .toLowerCase();

                            const strBusqueda = this.state.busqueda
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                                .toLowerCase();

                            return strA.indexOf(strBusqueda) !== -1;
                            
                      })
                      .sort(function (a, b) {
                        if (orden.value === 1) {
                          return a.ordenAlfabetico - b.ordenAlfabetico;
                        } else if (orden.value === 2) {
                          return a.ordenMenorPrecio - b.ordenMenorPrecio;
                        } else if (orden.value === 3) {
                          return a.ordenMayorPrecio - b.ordenMayorPrecio;
                        } else if (orden.value === 4) {
                          return a.ordenUltimosSubidos - b.ordenUltimosSubidos;
                        } else if (orden.value === 5) {
                          return a.ordenMenosVendidos - b.ordenMenosVendidos;
                        } else if (orden.value === 6) {
                          return a.ordenMasVendidos - b.ordenMasVendidos;
                        }
                      })
                      .map((edicion, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex justify-content-between days itemTienda"
                          >
                            <LazyLoad style={{ height: "100%" }}>
                              <div className="whatsapp-share-button">
                                <ReactSVG
                                  src={compartir}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.compartir(edicion)}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                }}
                              >
                                <figure>
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div className="tags-container">
                                          {edicion.tagPuntosPromocionDescripcion ? (
                                            <div className="constainerMultiplicaPuntosItem">
                                              <figcaption className="titleTagMultiplicaPuntosItem"
                                               style={{
                                                margin: this.calcularMargin(edicion)
                                              }} >
                                                {edicion.tagPuntosPromocionDescripcion}
                                              </figcaption>{" "}
                                            </div>
                                          ) : null}
                                          {edicion.etiquetaDescuento.length > 0 ? (
                                            <div>
                                              {this.esOfertaConPromo(edicion) ? 
                                              <figcaption className="titleTagDescuentoItem"
                                              >
                                                {edicion.etiquetaDescuento}
                                              </figcaption>
                                              :
                                              <figcaption className="titleTagDescuentoItemSinPromo"
                                              >
                                                {edicion.etiquetaDescuento}
                                              </figcaption>
                                              }
                                              
                                            </div>
                                          ) : null}
                                        </div>
                                    <img
                                      src={
                                        edicion.imagen
                                          ? `data:image/jpeg;base64,${edicion.imagen}`
                                          : `${tiendaUrl}/${edicion.codigoEditor}.png`
                                      }
                                      onError={this.addDefaultSrc}
                                      style={{ maxWidth: "150px" }}
                                      alt="placeholder"
                                    />
                                    {edicion.tagMasVendido !== "" ? (
                                      <div className="titleTagItem-container">
                                        <figcaption className="titleTagItem">
                                          {edicion.tagMasVendido}
                                        </figcaption>{" "}
                                      </div>
                                    ) : null}
                                    {edicion.esPreventa === true ? (
                                      <div className="titleTagPreVentaItem-container">
                                        <figcaption className="titleTagPreVentaItem">
                                          Preventa
                                        </figcaption>{" "}
                                      </div>
                                    ) : null}
                                  </div>
                                </figure>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                  }}
                                >
                                  {this.cardItem(edicion)}
                                  {edicion.edicionProductoNoEditorialId !==
                                  null ? (
                                    <div
                                      className="producto-no-editorial-ver-caracteristicas"
                                      onClick={() => {
                                        this.setState({
                                          productoNoEditorialNombre:
                                            edicion.edicionDescripcionCorta,
                                        });
                                        this.setState({
                                          productoNoEditorialCaracteristicas:
                                            edicion.caracteristicasProducto,
                                        });
                                        this.setState({
                                          productoNoEditorialPrecioVenta:
                                            edicion.precioVenta,
                                        });
                                        this.setState({
                                          cantidadImagenes:
                                            edicion.cantidadImagenes,
                                        });
                                        this.setState({
                                          edicioncodigoEditor:
                                            edicion.codigoEditor,
                                        });
                                        this.setState({
                                          verCaracteristicasProductoNoEditorial: true,
                                        });
                                      }}
                                    >
                                      {" "}
                                      <a>Ver Características del producto</a>
                                    </div>
                                  ) : null}

                                  <div className="w-100 footerItem align-items-center d-flex justify-content-between">
                                    <div
                                      className="f-14 fw-500"
                                      style={{
                                        maxWidth: "66%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <span>
                                        Stock disponible:{" "}
                                        {edicion.stockDisponibleAlmacen < 0
                                          ? 0
                                          : edicion.stockDisponibleAlmacen}
                                      </span>{" "}
                                      <br />
                                      <div
                                        id={`excesoStock-${edicion.edicionId}`}
                                        style={{
                                          display:
                                            this.mostrarExcesoStock(edicion),
                                          color: "red",
                                        }}
                                      >
                                        El pedido sobrepasa el stock por{" "}
                                        {this.calcularExcedentePedido(edicion)}{" "}
                                        ejemplar/es
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                          cursor: "pointer",
                                          background: !edicion.cantidad
                                            ? "#FCFCFC"
                                            : "#F4F4F4",
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "50%",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          if (edicion.cantidad > 0) {
                                            edicion.cantidad--;
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockDisponibleAlmacen +
                                              1;
                                            edicion.cantidadBackup =
                                              edicion.cantidad;
                                            if (
                                              edicion.stockDisponibleAlmacen >=
                                              0
                                            ) {
                                              this.checkearEdicionEnCarrito(
                                                edicion
                                              );
                                              this.tiendaCarritoActualizar(
                                                edicion.edicionId,
                                                edicion.cantidad
                                              );
                                            }
                                            let { backendrows } = this.state;
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }
                                        }}
                                      >
                                        <ReactSVG
                                          src={restar}
                                          style={{
                                            color: !edicion.cantidad
                                              ? "#EAEAEA"
                                              : "#8E95A5",
                                            width: "11px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="f-13-5"
                                        style={{
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <input
                                          style={{
                                            backgroundColor:
                                              this.definirBackGround(edicion),
                                            border: "#343435 solid 1px",
                                          }}
                                          type="number"
                                          className="input-of-cantidades text-center"
                                          maxLength="5"
                                          id={`tienda-${edicion.edicionId}`}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.slice(0, 5))
                                          }
                                          value={edicion.cantidad}
                                          autocomplete="off"
                                          onFocus={(e) => {
                                            e.target.select();
                                          }}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.currentTarget.blur();
                                            }
                                          }}
                                          onBlur={(e) => {
                                            let { backendrows } = this.state;
                                            let regex = /[^0-9]/g;
                                            if (regex.test(e.target.value)) {
                                              e.target.value =
                                                e.target.value.replace(
                                                  regex,
                                                  ""
                                                );
                                            }
                                            edicion.cantidad =
                                              e.target.value === "" ||
                                              edicion.cantidad === "empty"
                                                ? 0
                                                : parseInt(e.target.value);
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockInicial -
                                              (edicion.cantidad === "empty"
                                                ? 0
                                                : edicion.cantidad);
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                            if (
                                              edicion.stockDisponibleAlmacen >=
                                                0 &&
                                              edicion.cantidadBackUp !==
                                                edicion.cantidad
                                            ) {
                                              edicion.cantidadBackUp =
                                                edicion.cantidad;
                                              this.checkearEdicionEnCarrito(
                                                edicion
                                              );
                                              this.tiendaCarritoActualizar(
                                                edicion.edicionId,
                                                edicion.cantidad
                                              );
                                            } else if (
                                              edicion.stockDisponibleAlmacen < 0
                                            ) {
                                              MySwal.fire({
                                                icon: "error",
                                                title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                showConfirmButton: true,
                                                confirmButtonText: "Aceptar",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            let { backendrows } = this.state;
                                            let regex = /[^0-9]/g;
                                            if (regex.test(e.target.value)) {
                                              e.target.value =
                                                e.target.value.replace(
                                                  regex,
                                                  ""
                                                );
                                            }
                                            e.target.value =
                                              e.target.value === "0"
                                                ? e.target.value
                                                : e.target.value.replace(
                                                    /^(0+)/g,
                                                    ""
                                                  );
                                            edicion.cantidad =
                                              e.target.value === ""
                                                ? "empty"
                                                : parseInt(e.target.value);
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockInicial -
                                              (edicion.cantidad === "empty"
                                                ? 0
                                                : edicion.cantidad);
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                          cursor: "pointer",
                                          background:
                                            edicion.stockDisponibleAlmacen <= 0
                                              ? "#FCFCFC"
                                              : "#F4F4F4",
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "50%",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          if (
                                            edicion.stockDisponibleAlmacen > 0
                                          ) {
                                            edicion.cantidad++;
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockDisponibleAlmacen -
                                              1;
                                            edicion.cantidadBackup =
                                              edicion.cantidad;
                                            this.checkearEdicionEnCarrito(
                                              edicion
                                            );
                                            this.tiendaCarritoActualizar(
                                              edicion.edicionId,
                                              edicion.cantidad
                                            );
                                            let { backendrows } = this.state;
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }
                                        }}
                                      >
                                        <ReactSVG
                                          src={sumar}
                                          style={{
                                            color:
                                              edicion.stockDisponibleAlmacen <=
                                              0
                                                ? "#EAEAEA"
                                                : "#8E95A5",
                                            width: "11px",
                                            height: "18px",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        {/* Se agrego para que no seleccione la imagen al hacer doble click en sumar */}
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </LazyLoad>
                          </div>
                        );
                      })
                    }

                    {this.state.bannerMarketingShow ? (
                      <React.Fragment>
                        {/* Descripcion banner */}
                        <div className="w-100 d-flex justify-content-center align-items-center text-center">
                          <div
                            style={{
                              color: "#5775AA",
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginBottom: "10px",
                              marginTop: "18px",
                            }}
                          >
                            {this.state.bannerSeleccionado.descripcion}
                          </div>
                        </div>
                        {/* Listado de ediciones configuradas */}
                        {backendrows
                          .filter(
                            (a) =>
                              JSON.stringify(Object.values(a))
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                                .toLowerCase()
                                .indexOf(
                                  this.state.busquedaPorEdicionBanner.toLowerCase()
                                ) !== -1
                          )
                          .filter(
                            (e) =>
                              this.state.bannerEdicionesConfiguradas.indexOf(
                                e.edicionId
                              ) !== -1
                          )
                          .sort(function (a, b) {
                            if (orden.value === 1) {
                              return a.ordenAlfabetico - b.ordenAlfabetico;
                            } else if (orden.value === 2) {
                              return a.ordenMenorPrecio - b.ordenMenorPrecio;
                            } else if (orden.value === 3) {
                              return a.ordenMayorPrecio - b.ordenMayorPrecio;
                            } else if (orden.value === 4) {
                              return (
                                a.ordenUltimosSubidos - b.ordenUltimosSubidos
                              );
                            } else if (orden.value === 5) {
                              return (
                                a.ordenMenosVendidos - b.ordenMenosVendidos
                              );
                            } else if (orden.value === 6) {
                              return a.ordenMasVendidos - b.ordenMasVendidos;
                            }
                          })
                          .map((edicion, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between days itemTienda"
                              >
                                <LazyLoad style={{ height: "100%" }}>
                                  <div className="whatsapp-share-button">
                                    <ReactSVG
                                      src={compartir}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.compartir(edicion)}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                    }}
                                  >
                                    <figure>
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <div className="tags-container">
                                          {edicion.tagPuntosPromocionDescripcion ? (
                                            <div className="constainerMultiplicaPuntosItem">
                                              <figcaption className="titleTagMultiplicaPuntosItem"
                                               style={{
                                                margin: this.calcularMargin(edicion)
                                              }} >
                                                {edicion.tagPuntosPromocionDescripcion}
                                              </figcaption>{" "}
                                            </div>
                                          ) : null}
                                          {edicion.etiquetaDescuento.length > 0 ? (
                                            <div>
                                            {this.esOfertaConPromo(edicion) ? 
                                            <figcaption className="titleTagDescuentoItem"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            :
                                            <figcaption className="titleTagDescuentoItemSinPromo"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            }
                                            
                                          </div>
                                          ) : null}
                                        </div>
                                        <img
                                          src={
                                            edicion.imagen
                                              ? `data:image/jpeg;base64,${edicion.imagen}`
                                              : `${tiendaUrl}/${edicion.codigoEditor}.png`
                                          }
                                          onError={this.addDefaultSrc}
                                          style={{ maxWidth: "150px" }}
                                          alt="placeholder"
                                        />
                                        {edicion.tagMasVendido !== "" ? (
                                          <div className="titleTagItem-container">
                                            <figcaption className="titleTagItem">
                                              {edicion.tagMasVendido}
                                            </figcaption>{" "}
                                          </div>
                                        ) : null}
                                        {edicion.esPreventa === true ? (
                                          <div className="titleTagPreVentaItem-container">
                                            <figcaption className="titleTagPreVentaItem">
                                              Preventa
                                            </figcaption>{" "}
                                          </div>
                                        ) : null}
                                      </div>
                                    </figure>
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%",
                                      }}
                                    >
                                      {this.cardItem(edicion)}
                                      <div
                                        className="w-100 footerItem align-items-center d-flex justify-content-between"
                                        style={{ margin: "10px 0 0" }}
                                      >
                                        <div
                                          className="f-14 fw-500"
                                          style={{
                                            maxWidth: "66%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <span>
                                            Stock disponible:{" "}
                                            {edicion.stockDisponibleAlmacen < 0
                                              ? 0
                                              : edicion.stockDisponibleAlmacen}
                                          </span>{" "}
                                          <br />
                                          <div
                                            id={`excesoStock-${edicion.edicionId}`}
                                            style={{
                                              display:
                                                this.mostrarExcesoStock(
                                                  edicion
                                                ),
                                              color: "red",
                                            }}
                                          >
                                            El pedido sobrepasa el stock por{" "}
                                            {this.calcularExcedentePedido(
                                              edicion
                                            )}{" "}
                                            ejemplar/es
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                          <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              cursor: "pointer",
                                              background: !edicion.cantidad
                                                ? "#FCFCFC"
                                                : "#F4F4F4",
                                              width: "26px",
                                              height: "26px",
                                              borderRadius: "50%",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              if (edicion.cantidad > 0) {
                                                edicion.cantidad--;
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockDisponibleAlmacen +
                                                  1;
                                                edicion.cantidadBackup =
                                                  edicion.cantidad;
                                                if (
                                                  edicion.stockDisponibleAlmacen >=
                                                  0
                                                ) {
                                                  this.checkearEdicionEnCarrito(
                                                    edicion
                                                  );
                                                  this.tiendaCarritoActualizar(
                                                    edicion.edicionId,
                                                    edicion.cantidad
                                                  );
                                                }
                                                let { backendrows } =
                                                  this.state;
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }
                                            }}
                                          >
                                            <ReactSVG
                                              src={restar}
                                              style={{
                                                color: !edicion.cantidad
                                                  ? "#EAEAEA"
                                                  : "#8E95A5",
                                                width: "11px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="f-13-5"
                                            style={{
                                              marginLeft: "10px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <input
                                              style={{
                                                backgroundColor:
                                                  this.definirBackGround(
                                                    edicion
                                                  ),
                                                border: "#343435 solid 1px",
                                              }}
                                              type="number"
                                              className="input-of-cantidades text-center"
                                              maxLength="5"
                                              id={`tienda-${edicion.edicionId}`}
                                              onInput={(e) =>
                                                (e.target.value =
                                                  e.target.value.slice(0, 5))
                                              }
                                              value={edicion.cantidad}
                                              autocomplete="off"
                                              onFocus={(e) => {
                                                e.target.select();
                                              }}
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  e.currentTarget.blur();
                                                }
                                              }}
                                              onBlur={(e) => {
                                                let { backendrows } =
                                                  this.state;
                                                let regex = /[^0-9]/g;
                                                if (
                                                  regex.test(e.target.value)
                                                ) {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      regex,
                                                      ""
                                                    );
                                                }
                                                edicion.cantidad =
                                                  e.target.value === "" ||
                                                  edicion.cantidad === "empty"
                                                    ? 0
                                                    : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockInicial -
                                                  (edicion.cantidad === "empty"
                                                    ? 0
                                                    : edicion.cantidad);
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                                if (
                                                  edicion.stockDisponibleAlmacen >=
                                                    0 &&
                                                  edicion.cantidadBackUp !==
                                                    edicion.cantidad
                                                ) {
                                                  edicion.cantidadBackUp =
                                                    edicion.cantidad;
                                                  this.checkearEdicionEnCarrito(
                                                    edicion
                                                  );
                                                  this.tiendaCarritoActualizar(
                                                    edicion.edicionId,
                                                    edicion.cantidad
                                                  );
                                                } else if (
                                                  edicion.stockDisponibleAlmacen <
                                                  0
                                                ) {
                                                  MySwal.fire({
                                                    icon: "error",
                                                    title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                    showConfirmButton: true,
                                                    confirmButtonText:
                                                      "Aceptar",
                                                  });
                                                }
                                              }}
                                              onChange={(e) => {
                                                let { backendrows } =
                                                  this.state;
                                                let regex = /[^0-9]/g;
                                                if (
                                                  regex.test(e.target.value)
                                                ) {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      regex,
                                                      ""
                                                    );
                                                }
                                                e.target.value =
                                                  e.target.value === "0"
                                                    ? e.target.value
                                                    : e.target.value.replace(
                                                        /^(0+)/g,
                                                        ""
                                                      );
                                                edicion.cantidad =
                                                  e.target.value === ""
                                                    ? "empty"
                                                    : parseInt(e.target.value);
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockInicial -
                                                  (edicion.cantidad === "empty"
                                                    ? 0
                                                    : edicion.cantidad);
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              cursor: "pointer",
                                              background:
                                                edicion.stockDisponibleAlmacen <=
                                                0
                                                  ? "#FCFCFC"
                                                  : "#F4F4F4",
                                              width: "26px",
                                              height: "26px",
                                              borderRadius: "50%",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              if (
                                                edicion.stockDisponibleAlmacen >
                                                0
                                              ) {
                                                edicion.cantidad++;
                                                edicion.stockDisponibleAlmacen =
                                                  edicion.stockDisponibleAlmacen -
                                                  1;
                                                edicion.cantidadBackup =
                                                  edicion.cantidad;
                                                this.checkearEdicionEnCarrito(
                                                  edicion
                                                );
                                                this.tiendaCarritoActualizar(
                                                  edicion.edicionId,
                                                  edicion.cantidad
                                                );
                                                let { backendrows } =
                                                  this.state;
                                                let ind = backendrows.findIndex(
                                                  (e) =>
                                                    e.edicionId ===
                                                    edicion.edicionId
                                                );
                                                backendrows[ind] = edicion;
                                                this.setState({ backendrows });
                                              }
                                            }}
                                          >
                                            <ReactSVG
                                              src={sumar}
                                              style={{
                                                color:
                                                  edicion.stockDisponibleAlmacen <=
                                                  0
                                                    ? "#EAEAEA"
                                                    : "#8E95A5",
                                                width: "11px",
                                                height: "18px",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            {/* Se agrego para que no seleccione la imagen al hacer doble click en sumar */}
                                            &nbsp;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </LazyLoad>
                              </div>
                            );
                          })}
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {/* Banners */}
              {!this.state.bannerMarketingShow &&
              this.state.flagCatalogoCategoria &&
              !this.state.seleccionadasFlag &&
              !this.state.categoriaSeleccionada &&
              this.state.banners.length > 0 ? (
                <div
                  style={{
                    maxWidth: "550px",
                    marginBottom: "20px",
                    position: "relative",
                    left: "50%",
                    transform: "translate(-50%, 0  )",
                  }}
                >
                  <Carousel>
                    {this.state.banners.map((banner, index) => {
                      return (
                        <Carousel.Item interval={3000}>
                          <img
                            className="d-block w-100 carousel-img"
                            src={banner.urlBanner}
                            onClick={() => {
                              if (banner.linkBanner) {
                                let url = banner.linkBanner;
                                window.open(url, "_blank").focus();
                              } else if (banner.tieneConfiguracion) {
                                this.setState({ bannerMarketingShow: true });
                                this.props.hideMenu(false);
                                const aux = {
                                  bannerId: banner.bannerId,
                                  descripcion: banner.descripcion,
                                };
                                this.setState({ bannerSeleccionado: aux });
                                this.bannerEdicionesConfiguradas(
                                  banner.bannerId
                                );
                              }
                            }}
                            onError={this.addDefaultBannerSrc}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              ) : null}

              {/* Seccion Destacados */}
              {!this.state.bannerMarketingShow &&
              !this.state.seleccionadasFlag &&
              this.state.flagCatalogoCategoria &&
              !this.state.categoriaSeleccionada ? (
                <div className="pedido" style={{ marginBottom: "50px" }}>
                  <div
                    style={{
                      color: "#62759D",
                      fontWeight: "bold",
                      width: "50%",
                      borderBottom: "2px solid black",
                      marginBottom: "20px",
                    }}
                  >
                    DESTACADOS
                  </div>
                  <div>
                    {backendrows
                      .filter(
                        (a) =>
                          JSON.stringify(Object.values(a))
                            .normalize("NFD")
                            .replace(/\p{Diacritic}/gu, "")
                            .toLowerCase()
                            .indexOf(
                              this.state.busquedaPorCategoria.toLowerCase()
                            ) !== -1
                      )
                      .filter(
                        (e) =>
                          e.productoGrupoIds !== null &&
                          e.productoGrupoIds
                            .match(/\d+/g)
                            .map(Number)
                            .includes(this.state.categoriaId)
                      )
                      .sort(function (a, b) {
                        if (orden.value === 1) {
                          return a.ordenAlfabetico - b.ordenAlfabetico;
                        } else if (orden.value === 2) {
                          return a.ordenMenorPrecio - b.ordenMenorPrecio;
                        } else if (orden.value === 3) {
                          return a.ordenMayorPrecio - b.ordenMayorPrecio;
                        } else if (orden.value === 4) {
                          return a.ordenUltimosSubidos - b.ordenUltimosSubidos;
                        } else if (orden.value === 5) {
                          return a.ordenMenosVendidos - b.ordenMenosVendidos;
                        } else if (orden.value === 6) {
                          return a.ordenMasVendidos - b.ordenMasVendidos;
                        }
                      })
                      .map((edicion, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex justify-content-between days itemTienda"
                          >
                            <LazyLoad style={{ height: "100%" }}>
                              <div className="whatsapp-share-button">
                                <ReactSVG
                                  src={compartir}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.compartir(edicion)}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                }}
                              >
                                <figure>
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div className="tags-container">
                                          {edicion.tagPuntosPromocionDescripcion ? (
                                            <div className="constainerMultiplicaPuntosItem">
                                              <figcaption className="titleTagMultiplicaPuntosItem"
                                               style={{
                                                margin: this.calcularMargin(edicion)
                                              }} >
                                                {edicion.tagPuntosPromocionDescripcion}
                                              </figcaption>{" "}
                                            </div>
                                          ) : null}
                                          {edicion.etiquetaDescuento.length > 0 ? (
                                            <div>
                                            {this.esOfertaConPromo(edicion) ? 
                                            <figcaption className="titleTagDescuentoItem"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            :
                                            <figcaption className="titleTagDescuentoItemSinPromo"
                                            >
                                              {edicion.etiquetaDescuento}
                                            </figcaption>
                                            }
                                            
                                          </div>
                                          ) : null}
                                        </div>
                                    <img
                                      src={
                                        edicion.imagen
                                          ? `data:image/jpeg;base64,${edicion.imagen}`
                                          : `${tiendaUrl}/${edicion.codigoEditor}.png`
                                      }
                                      onError={this.addDefaultSrc}
                                      style={{ maxWidth: "150px" }}
                                      alt="placeholder"
                                    />
                                    {edicion.tagMasVendido !== "" ? (
                                      <div className="titleTagItem-container">
                                        <figcaption className="titleTagItem">
                                          {edicion.tagMasVendido}
                                        </figcaption>{" "}
                                      </div>
                                    ) : null}
                                    {edicion.esPreventa === true ? (
                                      <div className="titleTagPreVentaItem-container">
                                        <figcaption className="titleTagPreVentaItem">
                                          Preventa
                                        </figcaption>{" "}
                                      </div>
                                    ) : null}
                                  </div>
                                </figure>

                                <div
                                  style={{
                                    marginTop: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                  }}
                                >
                                  {this.cardItem(edicion)}
                                  <div className="w-100 footerItem align-items-center d-flex justify-content-between">
                                    <div
                                      className="f-14 fw-500"
                                      style={{
                                        maxWidth: "66%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <span>
                                        Stock disponible:{" "}
                                        {edicion.stockDisponibleAlmacen < 0
                                          ? 0
                                          : edicion.stockDisponibleAlmacen}
                                      </span>{" "}
                                      <br />
                                      <div
                                        id={`excesoStock-${edicion.edicionId}`}
                                        style={{
                                          display:
                                            this.mostrarExcesoStock(edicion),
                                          color: "red",
                                        }}
                                      >
                                        El pedido sobrepasa el stock por{" "}
                                        {this.calcularExcedentePedido(edicion)}{" "}
                                        ejemplar/es
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                          cursor: "pointer",
                                          background: !edicion.cantidad
                                            ? "#FCFCFC"
                                            : "#F4F4F4",
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "50%",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          if (edicion.cantidad > 0) {
                                            edicion.cantidad--;
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockDisponibleAlmacen +
                                              1;
                                            edicion.cantidadBackup =
                                              edicion.cantidad;
                                            if (
                                              edicion.stockDisponibleAlmacen >=
                                              0
                                            ) {
                                              this.checkearEdicionEnCarrito(
                                                edicion
                                              );
                                              this.tiendaCarritoActualizar(
                                                edicion.edicionId,
                                                edicion.cantidad
                                              );
                                            }
                                            let { backendrows } = this.state;
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }
                                        }}
                                      >
                                        <ReactSVG
                                          src={restar}
                                          style={{
                                            color: !edicion.cantidad
                                              ? "#EAEAEA"
                                              : "#8E95A5",
                                            width: "11px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="f-13-5"
                                        style={{
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <input
                                          style={{
                                            backgroundColor:
                                              this.definirBackGround(edicion),
                                            border: "#343435 solid 1px",
                                          }}
                                          type="number"
                                          className="input-of-cantidades text-center"
                                          maxLength="5"
                                          id={`tienda-${edicion.edicionId}`}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.slice(0, 5))
                                          }
                                          value={edicion.cantidad}
                                          autocomplete="off"
                                          onFocus={(e) => {
                                            e.target.select();
                                          }}
                                          onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                              e.currentTarget.blur();
                                            }
                                          }}
                                          onBlur={(e) => {
                                            let { backendrows } = this.state;
                                            let regex = /[^0-9]/g;
                                            if (regex.test(e.target.value)) {
                                              e.target.value =
                                                e.target.value.replace(
                                                  regex,
                                                  ""
                                                );
                                            }
                                            edicion.cantidad =
                                              e.target.value === "" ||
                                              edicion.cantidad === "empty"
                                                ? 0
                                                : parseInt(e.target.value);
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockInicial -
                                              (edicion.cantidad === "empty"
                                                ? 0
                                                : edicion.cantidad);
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                            if (
                                              edicion.stockDisponibleAlmacen >=
                                                0 &&
                                              edicion.cantidadBackUp !==
                                                edicion.cantidad
                                            ) {
                                              edicion.cantidadBackUp =
                                                edicion.cantidad;
                                              this.checkearEdicionEnCarrito(
                                                edicion
                                              );
                                              this.tiendaCarritoActualizar(
                                                edicion.edicionId,
                                                edicion.cantidad
                                              );
                                            } else if (
                                              edicion.stockDisponibleAlmacen < 0
                                            ) {
                                              MySwal.fire({
                                                icon: "error",
                                                title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                showConfirmButton: true,
                                                confirmButtonText: "Aceptar",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            let { backendrows } = this.state;
                                            let regex = /[^0-9]/g;
                                            if (regex.test(e.target.value)) {
                                              e.target.value =
                                                e.target.value.replace(
                                                  regex,
                                                  ""
                                                );
                                            }
                                            e.target.value =
                                              e.target.value === "0"
                                                ? e.target.value
                                                : e.target.value.replace(
                                                    /^(0+)/g,
                                                    ""
                                                  );
                                            edicion.cantidad =
                                              e.target.value === ""
                                                ? "empty"
                                                : parseInt(e.target.value);
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockInicial -
                                              (edicion.cantidad === "empty"
                                                ? 0
                                                : edicion.cantidad);
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                          cursor: "pointer",
                                          background:
                                            edicion.stockDisponibleAlmacen <= 0
                                              ? "#FCFCFC"
                                              : "#F4F4F4",
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "50%",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          if (
                                            edicion.stockDisponibleAlmacen > 0
                                          ) {
                                            edicion.cantidad++;
                                            edicion.stockDisponibleAlmacen =
                                              edicion.stockDisponibleAlmacen -
                                              1;
                                            edicion.cantidadBackup =
                                              edicion.cantidad;
                                            this.checkearEdicionEnCarrito(
                                              edicion
                                            );
                                            this.tiendaCarritoActualizar(
                                              edicion.edicionId,
                                              edicion.cantidad
                                            );
                                            let { backendrows } = this.state;
                                            let ind = backendrows.findIndex(
                                              (e) =>
                                                e.edicionId ===
                                                edicion.edicionId
                                            );
                                            backendrows[ind] = edicion;
                                            this.setState({ backendrows });
                                          }
                                        }}
                                      >
                                        <ReactSVG
                                          src={sumar}
                                          style={{
                                            color:
                                              edicion.stockDisponibleAlmacen <=
                                              0
                                                ? "#EAEAEA"
                                                : "#8E95A5",
                                            width: "11px",
                                            height: "18px",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        {/* Se agrego para que no seleccione la imagen al hacer doble click en sumar */}
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </LazyLoad>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              {!this.state.seleccionadasFlag &&
              this.state.cantidadCarrito > 0 &&
              Object.keys(this.state.timerId).length === 0 ? (
                <div>
                  <div
                    id="boton-enviar"
                    className="d-flex justify-content-center align-items-center barra-enviar"
                  >
                    {/*BOTON CARRITO INFERIOR*/}
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => {
                        this.verCarrito();
                      }}
                      style={{
                        background: "#224372",
                        color: "white",
                        fontSize: "12px",
                        textAlign: "center",
                        cursor: "pointer",
                        borderRadius: "16px",
                        width: "105px",
                        height: "33px",
                      }}
                    >
                      {" "}
                      {"Ver Carrito"}
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>

        {this.state.verCaracteristicasProductoNoEditorial === true ? (
          <ModalProductoNoEditorial
            titulo={this.state.productoNoEditorialNombre}
            productoNoEditorialPrecioVenta={
              this.state.productoNoEditorialPrecioVenta
            }
            cantidadImagenes={this.state.cantidadImagenes}
            edicioncodigoEditor={this.state.edicioncodigoEditor}
            volver={() => {
              this.setState({ verCaracteristicasProductoNoEditorial: false });
              // Habilitar scroll grilla ediciones
              document
                .querySelector("#cargarpedidotienda")
                .removeEventListener("wheel", this.preventScroll);
            }}
          >
            {this.state.productoNoEditorialCaracteristicas}
          </ModalProductoNoEditorial>
        ) : null}

        {this.deshabilitarScroll()}
      </div>
    );
  }
}
