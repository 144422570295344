import * as React from "react";
import MenuQuston from "./Menu";
import Perfil from "./Perfil";
import menuSVG from "../assets/menu.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import brandLogo from "../assets/brand-logo.png";
import brandLogoMenu from "../assets/brand-logo-menu.png";
import urlServer from "../server";
import backArrow from "../assets/backArrow.svg";
import cross from "../assets/cross.svg";
import fotoPerfil from "../assets/perfil.svg";
import ReactGA, { set } from "react-ga";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Swal from "sweetalert2";
import bell from "../assets/bell.svg";
import { guardarConexion } from "../guardarConexion";
import {
  getNombreYapellido,
  getTelefono,
  getPermisos,
  setUltimoModuloAlmacenado,
  getUltimoModuloAlmacenado
} from "../localStorageManager";
import withReactContent from "sweetalert2-react-content";
import PopUp from "./popUp";
import PopUpDatosUsuario from "./popUpDatosUsuario";
import { version } from '../../package.json'

const versionActual = version.replace(/\./g, '')

const MySwal = withReactContent(Swal);

export default class CallMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backarrowExists: false,
      anchorEl: null,
      permisoHistorialCanjePuntos: false,
      permisoDarDeBajaUsuario: false,
      mostrarPopUp: false,
      mostrarDatos: false,
      datosPV: {},
      camposAModificar: {},
      datosIncompletos: null,
      opcionDarDeBaja: false,
      path: window.location.pathname,
      notificaciones: {
        notificacionNoLeidas: [],
        notificacionLeidas: [],
      },
      notificacionesNoLeidas: [],
      notificacionesNoLeidasCantidad: 0,
      listaPopUps: [],
      incentivoHabilitado: false
    };

    this.obtenerNotificaciones = this.obtenerNotificaciones.bind(this);
    this.consultarMostrarPopUpLista =
      this.consultarMostrarPopUpLista.bind(this);
    this.updateMostrarPopUp = this.updateMostrarPopUp.bind(this);
    this.updateMostrarDatos = this.updateMostrarDatos.bind(this);
    this.updateNumeritoCampanaAlLeerNotif =
      this.updateNumeritoCampanaAlLeerNotif.bind(this);
  }

  validarVencimientoToken(mensaje) {
    if (mensaje) {
      if (
        mensaje === "Authorization has been denied for this request." ||
        mensaje.indexOf("Unexpected token") !== -1
      ) {
        localStorage.removeItem("ddr-auth");
        localStorage.removeItem("ddr-token");
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("infoToken");
        // localStorage.removeItem('refresh-token');
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
        window.location.reload();
      }
    }
    return;
  }

  // RECUPERA LAS PRIMERAS 2 LETRAS DEL NOMBRE PARA COLOCAR EN EL PERFIL
  letrasMayus = (nombre) => {
    let array = nombre.split("");
    let mayus = array.filter((e) => e !== " " && e === e.toUpperCase());
    return mayus.slice(0, 2).join("");
  };

  // CIERRA EL MENÚ EN CASO DE HACER CLICK FUERA DE ÉL
  outTarget = (e) => {
    const menu = document.getElementById("menu");
    const navbar = document.getElementById("navbar");
    if (e.target !== menu && !navbar.contains(e.target)) {
      if (!this.props.hiddenMenu) {
        this.props.hideMenu(true);
      }
    }
    if (menu) {
      menu.style.left = "";
    }
  };

  //INICIA RECONOCIMIENTO "TOUCH" PARA DESLIZAR EL MENÚ
  touchAt = (e) => {
    const atX = parseInt(e.changedTouches[0].clientX);
    const atY = parseInt(e.changedTouches[0].clientY);
    let menu;
    let left = "";
    const touchMove = (event) => {
      if (e.target !== document.getElementById("navbar")) {
        menu = document.getElementById("menu");
        if (menu) {
          menu.style.transition = "";
          const Xaxis = e.changedTouches[0].clientX;
          left = parseInt(Xaxis - event.changedTouches[0].clientX);
          //MOVIMIENTO CUANDO EL MENÚ ESTÁ ABIERTO(PARA CERRARLO)
          if (!this.props.hiddenMenu) {
            if (left < 0) {
            } else {
              menu.style.left = -left + "px";
            }
            //MOVIMIENTO CUANDO EL MENÚ ESTÁ CERRAD(PARA ABRIRLO)
          } else {
            // if (-left < menu.offsetWidth) {
            //     if (left < -150) {
            //         menu.style.left = -(menu.offsetWidth + left) + 'px'
            //     }
            // } else {
            //     menu.style.left = '0px'
            // }
          }
        }
      }
    };

    //FINALIZA RECONOCIMIENTO "TOUCH"
    const touchEnd = (e) => {
      const endX = parseInt(e.changedTouches[0].clientX);
      const endY = parseInt(e.changedTouches[0].clientY);
      if (atX !== endX || atY !== endY) {
        if (left) {
          if (!this.props.hiddenMenu) {
            if (left > 80) {
              this.props.hideMenu(true);
              menu.style.left = "";
            } else {
              menu.style.left = "0px";
            }
          } else {
            if (left < -82) {
              // this.props.hideMenu(false)
              // menu.style.left = '0px'
            } else {
              this.props.hideMenu(true);
              menu.style.left = "";
            }
          }
          left = "";
        }
      }

      if (menu) {
        menu.style.transition = "left 0.5s";
      }
      menu = undefined;
      document.removeEventListener("touchmove", touchMove);
      document.removeEventListener("touchend", touchEnd);
    };

    document.addEventListener("touchmove", touchMove, false);
    document.addEventListener("touchend", touchEnd, false);
  };

  componentDidMount() {
    this.traerInfoPerfil();
    this.consultarApiInfoGeneral()
    let botoncito = document.getElementById("mi-perfil-item-menu");
    this.props.hideMenu(true);
    document.addEventListener("click", this.outTarget);
    document.addEventListener("touchstart", this.touchAt, false);

    const Links = JSON.parse(localStorage.infoToken)[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

    if (typeof Links !== "undefined") {
      Links.forEach((element) => {
        if (element === "KIOSCO.CanjePorPuntosHistorial") {
          this.setState({ permisoHistorialCanjePuntos: true });
        }
        if (element === "KIOSCO.BajaUsuarioHabilitada") {
          this.setState({ permisoDarDeBajaUsuario: true });
        }
      });
    }
  }

  obtenerNotificacionesNoLeidas(notificaciones) {
    let notificacionesNoLeidas = [];
    if (notificaciones) {
      notificacionesNoLeidas = notificaciones.filter(
        (notificacion) => !notificacion.leida
      );
    }

    return notificacionesNoLeidas;
  }

  obtenerNotificacionesLeidas(notificaciones) {
    let notificacionesLeidas = [];
    if (notificaciones) {
      notificacionesLeidas = notificaciones.filter(
        (notificacion) => notificacion.leida
      );
    }

    return notificacionesLeidas;
  }

  async componentDidUpdate(previousState) {
    const verificarArrow = document.getElementById("backarrow") ? true : false;

    if (verificarArrow !== this.state.backarrowExists) {
      this.setState({
        backarrowExists: document.getElementById("backarrow") ? true : false,
      });
    }

    const permisos = getPermisos();
    
    let ultimoModuloLocalStorage = getUltimoModuloAlmacenado()

    var modulo = window.location.pathname;

    if (modulo !== ultimoModuloLocalStorage) {
      setUltimoModuloAlmacenado()

      this.consultarApiInfoGeneral()
    }
  }

  async obtenerCantidadNotificacionesNoLeidas(fromUpdate = false) {
    let data = {
      puntoVentaId: null,
      notificacionId: null,
    };
    const response = await fetch(
      urlServer + "/api/notificacion/cantidadObtener",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        result = isNaN(result) ? 0 : result;
        if (!fromUpdate) {
          this.setState({ notificacionesNoLeidasCantidad: result });
          return;
        }

        return result;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(error);
      });

    return response;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.outTarget);
    document.removeEventListener("touchstart", this.touchAt);
  }

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  logOut = async () => {
    const apiUrl = urlServer + "/api/account/logout";
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.token);
    await fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      redirect: "manual",
    })
      .then((response) => response.text())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then((res) => {
        if (
          localStorage.getItem("infoToken") != null &&
          JSON.parse(localStorage.getItem("infoToken")).usuario_id > 0
        ) {
          let usuarioId = JSON.parse(
            localStorage.getItem("infoToken")
          ).usuario_id;
          guardarConexion("LOGOUT", usuarioId);
        }

        localStorage.removeItem("ddr-auth");
        localStorage.removeItem("ddr-token");
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("infoToken");
        localStorage.removeItem("refresh-token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("result");
        //
        localStorage.removeItem("menu-ProdMasVendido");
        this.setState({
          redirect: true,
        });
        this.props.setLanding([]);
        this.props.loggingOut();
        window.history.replaceState(null, "", "/");
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  };

  renderPopUp = async () => {
    const { mostrarPopUp } = this.state;
    return mostrarPopUp ? "flex" : "none";
  };

  getAddress = async (domicilio) => {
    try {
      let data;
      let urlArr = [
        domicilio.localidadDescripcion
          ? "&county=" + domicilio.localidadDescripcion
          : "",
        domicilio.provinciaDescripcion
          ? "&state=" + domicilio.provinciaDescripcion
          : "",
        domicilio.calle
          ? "&street=" + domicilio.numero + " " + domicilio.calle
          : "",
        domicilio.partidoDepartamentoDescripcion
          ? "&city=" + domicilio.partidoDepartamentoDescripcion
          : "",
      ];
      let i = 0;
      while (!data) {
        let params = "";
        let index = i;
        for (i = index; i < urlArr.length; i++) {
          params += urlArr[i];
        }
        i = index + 1;
        const url = (
          "https://nominatim.openstreetmap.org/search?format=json&country=Argentina" +
          params
        ).replace(" ", "%20");
        const response = await fetch(url)
          .then((res) => {
            if (res?.ok) {
              return res.json();
            } else {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          })
          .catch((error) => console.log(error));
        data = response ? (data = await response[0]) : null;

        if (i >= urlArr.length && !data) {
          break;
        }
      }
      return data;
    } catch (error) {
      console.log("ERROR DE OPEN STREET API >>>>>>>", error);
    }
  };

  traerInfoPerfil = async () => {
    const data = {
      puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(urlServer + "/api/puntoVenta/buscar", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(data),
      headers,
    })
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then((result) => {
        if (result) {
          let datosPV = result ? result : "";
          if (datosPV.domicilio) {
            if (!datosPV.domicilio.x || !datosPV.domicilio.y) {
              const coord = this.getAddress(datosPV.domicilio);
              if (coord) {
                datosPV.domicilio.x = coord.lat;
                datosPV.domicilio.y = coord.lon;
              }
            }
          }
          let tel = getTelefono();
          let nya = getNombreYapellido();

          let informacionContacto = {
            ...nya,
            tel,
          };

          datosPV = {
            ...datosPV,
            informacionContacto,
          };

          this.setState({
            datosPV,
          });
        }

        this.validarDatosPerfil();

        return result;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  setStateMostrarPopUp = () => {
    this.traerInfoPerfil();
    this.consultarMostrarPopUpLista();
  };

  validarDatosPerfil = () => {
    let camposModificar = [];

    let datosIncompletos = false;

    if (!this.state.datosPV.domicilio) {
      camposModificar = ["Calle", "Partido", "Provincia", "Localidad"];
      datosIncompletos = true;
    } else {
      if (!this.state.datosPV.domicilio.calle) {
        camposModificar.push("Calle");
        datosIncompletos = true;
      }

      if (!this.state.datosPV.domicilio.partidoDepartamentoDescripcion) {
        camposModificar.push("Partido");
        datosIncompletos = true;
      }

      if (!this.state.datosPV.domicilio.provinciaDescripcion) {
        camposModificar.push("Provincia");
        datosIncompletos = true;
      }

      if (!this.state.datosPV.domicilio.localidadDescripcion) {
        camposModificar.push("Localidad");
        datosIncompletos = true;
      }
    }

    if (!this.state.datosPV.informacionContacto) {
      let contactoArr = ["Nombre", "Apellido", "Telefono"];
      camposModificar.push(...contactoArr);
    } else {
      if (!this.state.datosPV.informacionContacto.nombre) {
        camposModificar.push("Nombre");
        datosIncompletos = true;
      }

      if (!this.state.datosPV.informacionContacto.apellido) {
        camposModificar.push("Apellido");
        datosIncompletos = true;
      }

      if (!this.state.datosPV.informacionContacto.tel) {
        camposModificar.push("Telefono");
        datosIncompletos = true;
      }
    }

    if (
      !this.state.datosPV.mediosPago.efectivo &&
      !this.state.datosPV.mediosPago.posnet &&
      !this.state.datosPV.mediosPago.billeteraVirtual
    ) {
      camposModificar.push("Medios de pago");
      datosIncompletos = true;
    }

    this.setState({
      camposAModificar: camposModificar,
      datosIncompletos: datosIncompletos,
    });
  };

  consultarMostrarPopUpLista = () => {
    let permisoNotif = getPermisos().includes("KIOSCO.Notificaciones");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    fetch(urlServer + `/api/app/popUp?permiso=${permisoNotif}`, {
      method: "POST",
      redirect: "manual",
      body: {},
      headers,
    })
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        let mostrarPopUp;
        let elemPopUp;
        
        if (result?.length > 0) result[0].flagParaMostrarUnSoloPopUp = true

        this.setState(
          {
            listaPopUps: result,
          },
          () => {
            elemPopUp = result.find(
              (currentElement) => currentElement.mostrarPopUp
            );
            mostrarPopUp = elemPopUp ? elemPopUp.mostrarPopUp : false;

            this.setState({
              mostrarPopUp: mostrarPopUp,
            });
          }
        );

        let body = document.getElementById("body");
        let campanita = document.getElementById("campanita");
        body.style.pointerEvents = "all";
        if (campanita) campanita.style.pointerEvents = "all";
        if (mostrarPopUp) {
          if (campanita) campanita.style.pointerEvents = "none";
          body.style.pointerEvents = "none";
        }
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  };

  setPartePopUp = (listadoPopUp) => {
    let mostrarPopUp;
    let elemPopUp;
     if (listadoPopUp?.length > 0) listadoPopUp[0].flagParaMostrarUnSoloPopUp = true

        this.setState(
          {
            listaPopUps: listadoPopUp,
          },
          () => {
            elemPopUp = listadoPopUp.find(
              (currentElement) => currentElement.mostrarPopUp
            );
            mostrarPopUp = elemPopUp ? elemPopUp.mostrarPopUp : false;

            this.setState({
              mostrarPopUp: mostrarPopUp,
            });
          }
        );

        let body = document.getElementById("body");
        let campanita = document.getElementById("campanita");
        body.style.pointerEvents = "all";
        if (campanita) campanita.style.pointerEvents = "all";
        if (mostrarPopUp) {
          if (campanita) campanita.style.pointerEvents = "none";
          body.style.pointerEvents = "none";
        }
  }

  setVersion = (versionResult) => {
      
    var versionDB = 0;
    if (typeof versionResult !== 'undefined' && versionResult.length > 0)
    {
        versionDB = parseInt(versionResult[0].versionValor,10);
    }

    if (versionDB > versionActual && (versionDB > localStorage.versionLocal || localStorage.versionLocal === undefined)) {
        this.actualizarPermisos(versionDB);
        /*
        localStorage.setItem("versionLocal", versionDB);
        MySwal.fire({
            icon: 'info',
            title: 'Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.',
            showConfirmButton: false,
            timer: 2000,
            customClass: 'swalIconCustom'
        }).then((result) => {
            if (result.value) {
                this.setState({ loading: true })
                window.location.reload();
            } else {
                window.location.reload();
            }
        })
        .catch(error => {
            console.log('error', error)
        })
        */
    }
  }

    actualizarPermisos = (versionDB) => {
        var infoToken = JSON.parse(localStorage.infoToken);
        var data = {
            usuarioNombre: infoToken.name,
            ambitoId: 7,
            entidadId: infoToken.entidad_id,
            permisoNombre: "kiosco",
        };
        var url = urlServer + "/api/account/permisos/kiosco/buscar";
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((permisos) => {
                infoToken[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ] = [...permisos];
                localStorage.setItem("infoToken", JSON.stringify(infoToken));
                localStorage.setItem("versionLocal", versionDB);

                MySwal.fire({
                    icon: "info",
                    title:
                        "Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.",
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: "swalIconCustom",
                })
                    .then((result) => {
                        if (result.value) {
                            this.setState({ loading: true });
                            window.location.reload();
                        } else {
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
            })
            .catch((error) => {
                console.log("buscar permisos > error :", error);
            });
    };


  setCantidadNotif = (cantidadNotif) => {
    cantidadNotif = isNaN(cantidadNotif) ? 0 : cantidadNotif;
    // if (!fromUpdate) {
    this.setState({ notificacionesNoLeidasCantidad: cantidadNotif });
      // return;
    // }
  }

  setIncentivoHabilitado = (incentivoHabilitado) => {
    this.setState({incentivoHabilitado: incentivoHabilitado})
  }

  seteadorDeEstados = (informacionGeneral) => {
    this.setPartePopUp(informacionGeneral.listaPopUps)
    this.setVersion(informacionGeneral.version)
    this.setCantidadNotif(informacionGeneral.numeroCantidadNotif)
    this.setIncentivoHabilitado(informacionGeneral.incentivoHabilitado)
  }

  
  consultarApiInfoGeneral = () => {
    let permisoNotif = getPermisos().includes("KIOSCO.Notificaciones");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    fetch(urlServer + `/api/app/informacionGeneral/obtener?permiso=${permisoNotif}`, {
      method: "POST",
      redirect: "manual",
      body: {},
      headers,
    })
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        this.seteadorDeEstados(result)
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  };

  updateMostrarDatos(bool) {
    this.setState({
      mostrarDatos: bool,
    });
  }

  updateMostrarPopUp(bool) {
    this.setState({
      mostrarPopUp: bool,
    });
  }

  async marcarNotifLeida(
    notifIndex,
    consultarMostrarPopUpLista,
    obtenerNotificaciones
  ) {
    // step 1: google analytics
    ReactGA.event({
      category: "Notificacion",
      action: "Leer notificacion",
    });

    // step 2: armar url
    let apiUrl = urlServer + `/api/notificacion/leer/${notifIndex}`;

    // step 3: armar auth
    let auth = "Bearer " + localStorage.token;

    // step 4: definir headers
    let myHeaders = {
      Authorization: auth,
    };

    // step 5: realizar la request
    await fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.exito) {
          consultarMostrarPopUpLista();
          obtenerNotificaciones();
        }
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  }

  renderizarTodosPopUps() {
    const {
      mostrarPopUp,
      mostrarDatos,
      datosIncompletos,
      datosPV,
      camposAModificar,
    } = this.state;
    return (
      <>
        {this.state.listaPopUps.map((popup) => {
          return popup.popUpTipo === "DatosControl" && popup.mostrarPopUp && popup?.flagParaMostrarUnSoloPopUp ? (
            <PopUpDatosUsuario
              mostrarPopUp={mostrarPopUp}
              mostrarDatos={mostrarDatos}
              datosIncompletos={datosIncompletos}
              hidePerfil={this.props.hidePerfil}
              datosPV={datosPV}
              updateMostrarPopUp={this.updateMostrarPopUp}
              updateMostrarDatos={this.updateMostrarDatos}
              GuardarDatosPerfil={this.GuardarDatosPerfil}
              camposAModificar={camposAModificar}
            />
          ) : // this.renderPopUpDatosControl()
          // en vez de esto tengo que poner un Enum con todos los tipos menos 'DatosControl'
          popup.popUpTipo !== "DatosControl" && popup?.flagParaMostrarUnSoloPopUp ? (
            <PopUp
              popUp={popup}
              marcarNotifLeida={this.marcarNotifLeida}
              consultarMostrarPopUpLista={this.consultarMostrarPopUpLista}
              obtenerNotificaciones={this.obtenerNotificaciones}
            />
          ) : null;
        })}
      </>
    );
  }

  removeFlagVolverDeCanje = () => {
    localStorage.removeItem("flagVolverDeCanje");
  };

  EnviarEmailDarDeBajaUsuario = () => {
    var infotoken = JSON.parse(localStorage.infoToken);
    var data = {
      email: infotoken.sub,
    };
    fetch(urlServer + "/api/account/enviarEmailDarDeBajaUsuario", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        Swal.fire({
          title:
            "Tu solicitud de darte de baja ha sido recibida con éxito. Por favor, revisa tu correo para completar la baja!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.value) {
            this.logOut();
          }
        });
      })
      .catch((error) => {
        var msjprodexc = `La operacion no pudo completarse, por favor comuniquelo a traves del canal de ayuda`;

        MySwal.fire({
          icon: "error",
          title: msjprodexc,
          showConfirmButton: false,
          //confirmButtonText: 'Aceptar',
          customClass: "swalIconCustom",
          timer: 2000,
        });

        this.validarVencimientoToken(error.message);
        console.log(error);
      });
  };

  obtenerNotificaciones() {
    let data = {
      puntoVentaId: null,
      notificacionId: null,
    };
    fetch(urlServer + "/api/notificacion/buscar", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        let cantidadNotif = await this.obtenerCantidadNotificacionesNoLeidas(
          true
        );
        this.updateNumeritoCampanaAlLeerNotif(cantidadNotif);
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(error);
      });
  }

  async obtenerNotificacionesUpdate() {
    let data = {
      usuarioId: null,
      notificacionId: null,
    };
    await fetch(urlServer + "/api/notificacion/buscar", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(error);
      });
  }

  renderNotifications() {
    const { notificacionesNoLeidasCantidad } = this.state;

    return (
      <React.Fragment>
        <div id="notificacionesNum" className="">
          {" "}
          {notificacionesNoLeidasCantidad}{" "}
        </div>
      </React.Fragment>
    );
  }

  updateNumeritoCampanaAlLeerNotif(cantidadNotifNoLeidas) {
    this.setState({
      notificacionesNoLeidasCantidad: cantidadNotifNoLeidas,
    });
  }

  render() {
    const { backarrowExists } = this.state;
    //const user = this.props
    const open = Boolean(this.state.anchorEl);

    return (
      <div
        id="navbar"
        className={
          "position-relative callMenu d-flex" +
          (this.props.path !== "/" && this.props.path !== ""
            ? " backwhite"
            : "")
        }
      >
        {/* CONSULTA SI HAY SUBLINKS PARA EL MENÚ, DE HABER CREA EL MENU */}
        {this.props.links.length !== 0 ? (
          <div
            id="menu"
            className={
              "position-fixed menu" +
              (this.props.hiddenMenu ? " hiddenMenu" : "")
            }
            style={{
              minWidth: "262px",
              width: "280px",
              transition: "left 0.5s",
            }}
          >
            {/* CRUZ PARA CERRAR EL MENÚ */}
            <br />
            <div
              onClick={() => this.props.hideMenu()}
              className="container text-left mx-2"
              style={{ color: "#8E95A5", cursor: "pointer" }}
            >
              <ReactSVG src={cross} style={{ width: "11px" }} />
            </div>
            <br />
            {/* // CRUZ PARA CERRAR EL MENÚ // */}

            {/* // MENÚ // */}
            <MenuQuston
              path={this.props.path}
              linksTienda={this.props.linksTienda}
              hideMenu={this.props.hideMenu}
              hiddenMenu={this.props.hiddenMenu}
              links={this.props.links}
            />
            {/* // MENÚ // */}
          </div>
        ) : null}
        {/* // CONSULTA SI HAY SUBLINKS PARA EL MENÚ, DE HABER MUESTRA EL SVG DEL MENU Y CREA EL MENU // */}

        {/* ESPERA A RECIBIR LOS DATOS DE PERFIL, CUANDO LOS RECIBE PASA LOS DATOS AL COMPONENTE "PERFIL" */}
        {!this.props.hiddenPerfil ? (
          <div
            id="perfil"
            style={{ overflowY: "scroll" }}
            className={
              "position-fixed perfil" +
              (this.props.hiddenPerfil ? " hiddenPerfil" : "")
            }
          >
            <br />
            <div
              onClick={() => {
                let body = document.getElementById("body");
                body.style.display = "block";
                this.traerInfoPerfil();
                this.consultarMostrarPopUpLista();
                if (!this.state.mostrarPopUp) {
                  let campanita = document.getElementById("campanita");
                  if (campanita) campanita.style.pointerEvents = "all";
                  body.style.pointerEvents = "all";
                }
                this.props.setStateNoEstaEnPerfil();
                this.props.hidePerfil();
              }}
              className="position-fixed back-arrow-box"
              style={{ color: "white", cursor: "pointer" }}
            >
              <ReactSVG src={backArrow} />
            </div>
            <br />
            <Perfil
              hidePerfil={this.props.hidePerfil}
              hiddenPerfil={this.props.hiddenPerfil}
              setStateEstaEnPerfil={this.props.setStateEstaEnPerfil}
              setStateNoEstaEnPerfil={this.props.setStateNoEstaEnPerfil}
              perfil={this.props.perfil}
              setLanding={this.props.setLanding}
              loggingOut={this.props.loggingOut}
              setStateMostrarPopUp={this.setStateMostrarPopUp}
              GuardarDatosPerfil={this.GuardarDatosPerfil}
            />
          </div>
        ) : null}
        {/* // ESPERA A RECIBIR LOS DATOS DE PERFIL, CUANDO LOS RECIBE PASA LOS DATOS AL COMPONENTE "PERFIL" // */}

        <div
          style={this.props.path !== "/" ? { zIndex: "1059" } : {}}
          className={
            "w-100 callMenu position-fixed d-flex" +
            (this.props.path !== "/" && this.props.path !== ""
              ? " backwhite"
              : "")
          }
        >
          {/* SI TIENE SUBLINKS HABILITA EL SVG DEL MENÚ PARA QUE EL USUARIO PUEDA DESPLEGARLO  INICIO*/}
          {this.props.links.length === 0 || backarrowExists ? (
            <div></div>
          ) : (
            <div
              id="call-menu"
              onClick={() => {
                this.props.setStateNoEstaEnPerfil();
                this.props.hideMenu();
              }}
            >
              <ReactSVG className="menuimg" src={menuSVG} />
            </div>
          )}
          {/* // SI TIENE SUBLINKS HABILITA EL SVG DEL MENÚ PARA QUE EL USUARIO PUEDA DESPLEGARLO FIN// */}

          {/* LOGO INSTITUCIONAL CON LINK A HOME */}

          {this.props.path === "/" ? (
            <Link to="/">
              <img
                className="brand-logo navbar-logo"
                src={brandLogo}
                alt="brand"
                onClick={() => {
                }}
              ></img>
            </Link>
          ) : (
            <Link to="/" onClick={this.removeFlagVolverDeCanje}>
              <img
                className="brand-logo navbar-logo"
                src={brandLogoMenu}
                alt="brand"
                onClick={() => {
                }}
              ></img>
            </Link>
          )}

          {/* // LOGO INSTITUCIONAL CON LINK A HOME // */}

          <div className="d-flex navbar-user">
            {/* // ICONO DE CAMPANA CON LINK A NOTIFICACIONES // */}
            {this.props.bell ? (
              // <Link
              //     to={`${window.location.pathname === "/Notificaciones" ? this.state.pathPrevio : "/Notificaciones" }`}
              //     state={{
              //
              //     }}
              // >
              <Link
                to={{
                  pathname: `${
                    window.location.pathname === "/Notificaciones"
                      ? this.state.pathPrevio
                      : "/Notificaciones"
                  }`,
                  data: {
                    updateNumeritoCampanaAlLeerNotif:
                      this.updateNumeritoCampanaAlLeerNotif,
                  },
                }}
              >
                <div
                  id="campanita"
                  className={
                    "bell" +
                    (this.state.path === "/Notificaciones"
                      ? " primarycolor"
                      : "")
                  }
                  onClick={() => {
                    this.setState({ pathPrevio: window.location.pathname });
                  }}
                  title="Notificaciones"
                >
                  <ReactSVG
                    src={bell}
                    style={{
                      width: "25px",
                      position: "relative",
                      right: "35px",
                      fill:
                        window.location.pathname === "/" ? "white" : "#09142E",
                    }}
                  />
                  {this.renderNotifications()}
                </div>
              </Link>
            ) : null}

            {/* Icono Menu mi perfil */}
            <div style={{ marginTop: "5px" }} className="profile-dropdown">
              <img
                className="pic"
                src={fotoPerfil}
                alt="Tu Perfil"
                style={{
                  userSelect: "none",
                  marginRight: "40px",
                  cursor: "pointer",
                }}
                onClick={this.handleClickMenu}
              />
            </div>
            {this.renderizarTodosPopUps()}
            {/* // ICONO DE AYUDA CON LINK A CANAL DE AYUDA// */}
            {/* <div>
                                <img
                                    src={canalAyuda}                                  
                                    alt="Canal de Ayuda"
                                    width={32}   
                                    height={28}
                                ></img>
                            </div> */}
          </div>
        </div>

        {/* Menu mi perfil */}
        <div>
          <Menu
            id="basic-menu"
            anchorEl={this.state.anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",

              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              },
            }}
          >
            <MenuItem
              id="mi-perfil-item-menu"
              selected={true}
              disabled={this.state.mostrarPopUp ? true : false}
              onClick={(e) => {
                ReactGA.event({
                  category: "Perfil",
                  action: "Ver mi Perfil",
                });
                let body = document.getElementById("body");

                body.style.display = "none";
                this.props.hidePerfil();
                this.handleCloseMenu();
              }}
              style={{
                color: "black",
                display: "block",
                minHeight: "26px",
                padding: "2px 12px",
                fontSize: "12px",
                borderBottom: "1px solid rgb(201 198 198)",
              }}
            >
              <PersonIcon fontSize="small" /> Mi Perfil
            </MenuItem>

            {this.state.permisoHistorialCanjePuntos ? (
              <div>
                <MenuItem
                  selected={true}
                  disabled={this.state.mostrarPopUp ? true : false}
                  onClick={() => {
                    ReactGA.event({
                      category: "Historial Canje/Puntos",
                      action: "Ver Historial Canje/Puntos",
                    });
                    this.props.history.push("/CanjePorPuntosHistorial");
                    this.handleCloseMenu();
                  }}
                  style={{
                    color: "black",
                    display: "block",
                    minHeight: "26px",
                    padding: "4px 12px 2px ",
                    fontSize: "12px",
                    borderBottom: "1px solid rgb(201 198 198)",
                  }}
                >
                  {" "}
                  <CreditScoreIcon fontSize="small" /> Historial de Puntos y
                  Canjes
                </MenuItem>
              </div>
            ) : null}

            {this.state.permisoDarDeBajaUsuario ? (
              <MenuItem
                selected={true}
                onClick={() => {
                  this.handleCloseMenu();
                  Swal.fire({
                    title:
                      "¿Estas seguro que deseas darte de baja de ParadaOnline?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Confirmar",
                  }).then((result) => {
                    if (result.value) {
                      this.setState({ loading: true }, () => {
                        this.EnviarEmailDarDeBajaUsuario();
                      });
                    }
                  });
                }}
                style={{
                  color: "black",
                  display: "block",
                  minHeight: "26px",
                  padding: "5px 12px 2px ",
                  fontSize: "12px",
                  borderBottom: "1px solid rgb(201 198 198)",
                }}
              >
                {" "}
                <PersonRemoveIcon fontSize="small" /> Darse de baja
              </MenuItem>
            ) : null}

            <MenuItem
              selected={true}
              onClick={() => {
                this.handleCloseMenu();
                Swal.fire({
                  title: "¿Estas seguro que deseas salir de ParadaOnline?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Salir",
                  customClass: "swalIconCustom",
                }).then((result) => {
                  if (result.value) {
                    this.setState({ loading: true }, () => {
                      this.logOut();
                    });
                  }
                });
              }}
              style={{
                color: "black",
                display: "block",
                minHeight: "26px",
                padding: "5px 12px 2px ",
                fontSize: "12px",
              }}
            >
              {" "}
              <ExitToAppIcon fontSize="small" /> Salir
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}
