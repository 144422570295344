import * as React from "react";
import { Title } from "../components/title";
import AsyncSelect from "react-select/async";
import { Form } from "react-bootstrap";
import urlServer from "../server";
import { ReactSVG } from "react-svg";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import pedidos from "../assets/pedidos.svg";
import backArrow from "../assets/backArrow.svg";
import { Redirect } from 'react-router-dom'
import tienda from '../assets/ic_cc.svg'
import CarritoAltaSuscripciones from "../components/carritoaltasuscripciones";
import { Modal } from "@material-ui/core";

const tiendaUrl = 'https://paradaonline.com.ar/s/img/tapas'

export default class AltaSuscripciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seccionDatosPersonales: true,
      seccionDireccion: false,
      seccionElegiTuPlan: false,
      tipoDocumento: { value: "DNI", label: "DNI" },
      nroDocumento: null,
      apellido: null,
      nombre: null,
      fechaNacimiento: "",
      genero: null,
      celularFijo: null,
      telefonoNumero: null,
      email: null,
      horaDesde: null,
      horaHasta: null,
      datosPersonalesCompletos: false,
      provinciaId: null,
      provinciaDescripcion: null,
      listarProvincias: [],
      partidoId: null,
      listarPartidos: [],
      localidadId: null,
      localidadDescripcion: null,
      listarLocalidades: [],
      localidadBarrio: null,
      codigoPostal: null,
      countryBarrio: null,
      calle: null,
      calleNro: null,
      piso: null,
      departamento: null,
      torre: null,
      cuerpo: null,
      calleLateral1: null,
      calleLateral2: null,
      datosDireccionCompletos: false,
      suscripcionProductosListado: [],
      verCarrito: false,
      alert: [],
      open: null,
      mostrarFlechaVoler: false,
      seGuardo:false
    };
  }

  validarVencimientoToken(mensaje) {
    if (mensaje) {
        if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
            localStorage.removeItem('ddr-auth');
            localStorage.removeItem('ddr-token');
            localStorage.removeItem('is_authenticated');
            localStorage.removeItem('token');
            localStorage.removeItem('infoToken');
            // localStorage.removeItem('refresh-token');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('expires_at');
            window.location.reload();
            return;
        }
    }
    return
  }

  addDefaultSrc(ev) {
    ev.target.src = tienda
  }
  scrollTo() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  getTiposDocumento = async () => {
    var tipos = [
      { value: "DNI", label: "DNI" },
      { value: "CUIL", label: "CUIL" },
      { value: "LC", label: "LC" },
      { value: "LE", label: "LE" },
    ];
    return tipos;
  };

  handleTiposDocumento = (e) => {
    this.setState({ tipoDocumento: e });
  };

  handleNroDocumento = (e) => {
    if(e.type === 'change')
    {
      this.setState({ nroDocumento: e.target.value });
    }
    else {
      this.setState({ nroDocumento: e.target.value.trim() });
    }
  };

  handleApellido = (e) => {
    if(e.type === 'change')
    {
      this.setState({ apellido: e.target.value });
    }
    else {
      this.setState({ apellido: e.target.value.trim() });
    }
  };

  handleNombre = (e) => {
    if(e.type === 'change')
    {
      this.setState({ nombre: e.target.value });
    }
    else {
      this.setState({ nombre: e.target.value.trim() });
    }
  };

  handleFechaNacimiento = (e) => {
    this.setState({ fechaNacimiento: e.target.value });
  };

  handleGenero = (e) => {
    this.setState({ genero: e.target.value });
  };

  handleCelularFijo = (e) => {
    this.setState({ celularFijo: e.target.value });
  };

  handleTelefonoNumero = (e) => {
    if(e.type === 'change')
    {
        this.setState({ telefonoNumero: e.target.value });
    }
    else {
        this.setState({ telefonoNumero: e.target.value.trim() });
    }
  };

  handleEmail = (e) => {
    if(e.type === 'change')
    {
      this.setState({ email: e.target.value });
    }
    else {
      this.setState({ email: e.target.value.trim() });
    }
  };

  handleHoraDesde = (e) => {
    this.setState({ horaDesde: e.target.value > 24 ? 24 : e.target.value });
  };

  handleHoraHasta = (e) => {
    this.setState({ horaHasta: e.target.value > 24 ? 24 : e.target.value });
  };

  verificarDatosPersonalesCompleto = () => {
    let phoneEx =
      /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g;
    if (
      this.state.tipoDocumento &&
      this.state.tipoDocumento !== "" &&
      this.state.nroDocumento &&
      this.state.nroDocumento !== "" &&
      this.state.apellido &&
      this.state.apellido !== "" &&
      this.state.nombre &&
      this.state.nombre !== "" &&
      this.state.fechaNacimiento &&
      this.state.fechaNacimiento !== "" &&
      this.state.genero &&
      this.state.genero !== "" &&
      this.state.celularFijo &&
      this.state.celularFijo !== "" &&
      this.state.telefonoNumero &&
      this.state.telefonoNumero !== "" &&
      this.state.telefonoNumero.match(phoneEx) &&
      this.state.email &&
      this.state.email !== "" &&
      this.state.horaDesde &&
      this.state.horaDesde !== "" &&
      this.state.horaHasta &&
      this.state.horaHasta !== "" &&
      parseInt(this.state.horaHasta) > parseInt(this.state.horaDesde)
    ) {
      if (this.state.datosPersonalesCompletos === false) {
        this.setState({ datosPersonalesCompletos: true });
      }
    } else {
      if (this.state.datosPersonalesCompletos === true) {
        this.setState({ datosPersonalesCompletos: false });
      }
    }
  };

  verificarCamposDireccionCompletos = () => {
    if (
      this.state.provinciaId &&
      this.state.partidoId &&
      this.state.localidadId &&
      this.state.codigoPostal &&
      this.state.codigoPostal !== "" &&
      this.state.calle &&
      this.state.calle !== "" &&
      this.state.calleNro &&
      this.state.calleNro !== ""
    ) {
      if (this.state.datosDireccionCompletos === false) {
        this.setState({ datosDireccionCompletos: true });
      }
    } else {
      if (this.state.datosDireccionCompletos === true) {
        this.setState({ datosDireccionCompletos: false });
      }
    }
  };

  componentDidUpdate = () => {
    this.verificarDatosPersonalesCompleto();
    this.verificarCamposDireccionCompletos();
  };

  componentDidMount = () => {
    this.scrollTo();
    this.getProvincias();
    this.getSuscripcionProductos();
  };

  getSuscripcionProductos = () => {
    var url = urlServer + "/api/suscripciones/productos/listado";
    fetch(url, {
      method: "POST",
      body: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.validarVencimientoToken(result.message);
        var aux = result.map((prod, index) => {
          if (prod.Tipo === "Diarios") {
            return {
              productoId: prod.ProductoId,
              descripcion: prod.Descripcion,
              tipo: prod.Tipo,
              codigoEditor: prod.CodigoEditor,
              cantidad: 0,
              domingo: 0,
              lunes: 0,
              martes: 0,
              miercoles: 0,
              jueves: 0,
              viernes: 0,
              sabado: 0,
            };
          } else {
            return {
              productoId: prod.ProductoId,
              descripcion: prod.Descripcion,             
              tipo: prod.Tipo,              
              codigoEditor: prod.CodigoEditor, 
              cantidad: 0,
            };
          }
        });

        this.setState({ suscripcionProductosListado: aux });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log(error);
      });
  };

  soloNumeros = (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  componentWillUnmount() {
     this.props.hideMenu(!this.props.hiddenMenu)
  }
  renderSeccionDatosPersonales = () => {
    return (
      <div>
        <form>
          <div>
            Tipo y N&uacute;mero de Documento{" "}
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className="d-flex">
            <div>
              <AsyncSelect
                className="alta-suscripciones-select-tipodocumento"
                cacheOptions
                loadOptions={this.getTiposDocumento}
                defaultOptions
                onChange={this.handleTiposDocumento}
                placeholder={"Seleccione un tipo de documento"}
                value={this.state.tipoDocumento}
              />
            </div>
            <div>
              <input
                id="numeroDocumento"
                className="w-100 form-control"
                type="text"
                placeholder="N° de Documento"
                maxLength="15"
                onBlur={this.handleNroDocumento}
                onChange={this.handleNroDocumento}
                value={this.state.nroDocumento}
              />
            </div>
          </div>
          <div>
            <div>
              <div className="suscripciones-nombreapellido">
                <div>
                  Apellido <span style={{ color: "red" }}>*</span>
                </div>
                <input
                  id="apellido"
                  className="form-control"
                  type="text"
                  placeholder="Apellido"
                  maxLength="100"
                  onChange={this.handleApellido}
                  onBlur={this.handleApellido}
                  value={this.state.apellido}
                />
              </div>
              <div className="suscripciones-nombreapellido">
                <div>
                  Nombre <span style={{ color: "red" }}>*</span>
                </div>
                <input
                  id="nombre"
                  className="form-control"
                  type="text"
                  placeholder="Nombre"
                  maxLength="100"
                  onBlur={this.handleNombre}
                  onChange={this.handleNombre}
                  value={this.state.nombre}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="row" style={{ marginTop: "5px" }}>
              <div className="col-md-4 col-sm-6">
                <div>
                  Fecha de Nacimiento <span style={{ color: "red" }}>*</span>
                </div>

                    <Form.Group style={{marginBottom:"5px"} }>
                        <Form.Control
                        id="fechanacimiento"
                        type="date"
                        name="dob"
                        placeholder="Fecha de nacimiento"
                        onChange={this.handleFechaNacimiento}
                        value={this.state.fechaNacimiento}
                        />
                    </Form.Group>
              </div>
              <div className="col-md-4 col-sm-6" onChange={this.handleGenero}>
                <div>
                  Genero <span style={{ color: "red" }}>*</span>
                </div>
                <div className="form-check form-check-inline ">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="genero"
                    id="generomasculino"
                    value="Hombre"
                    checked={this.state.genero === "Hombre"}
                  />
                  <label className="form-check-label" htmlFor="generomasculino">
                    Masculino
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="genero"
                    id="generofemenino"
                    value="Mujer"
                    checked={this.state.genero === "Mujer"}
                  />
                  <label className="form-check-label" htmlFor="generofemenino">
                    Femenino
                  </label>
                </div>
              </div>
            </div>
          </div>
                <br />
                <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      Telefono <span style={{ color: "red" }}>*</span>
                    </div>
                    <div onChange={this.handleCelularFijo}  >
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipotelefono"
                          id="tipotelefonocelular"
                          value="Celular"
                          checked={this.state.celularFijo === "Celular"}
                        />
                        <label
                                className="form-check-label"
                                style={{ marginBottom: '-3px' }}
                          htmlFor="tipotelefonocelular"
                        >
                          Celular
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipotelefono"
                          id="tipotelefonofijo"
                          value="Fijo"
                          checked={this.state.celularFijo === "Fijo"}
                        />
                            <label className="form-check-label"
                                style={{ marginBottom: '-3px' }}
                                htmlFor="tipotelefonofijo">
                          Fijo
                        </label>
                      </div>
                    </div>
                   
                    <div style={{position:"absolute", marginLeft:"295px"} }>
                        <span
                            data-toggle="modal"
                            data-target="#modalInfoTelefono"
                            style={{
                                cursor: "pointer",
                                backgroundColor: "#224372",
                                color: "white",
                                padding: "2px 4px",
                                borderRadius: "15px",
                                fontSize: "12px",
                            }}
                        >
                            &nbsp;?&nbsp;
                        </span>
                    </div>
                </div>
          <div className="d-flex">
            <div>
              <input
                style={{ width: "70px" }}
                className="form-control"
                type="text"
                maxLength="5"
                value="+54 0"
                disabled
              />
            </div>
            <div>
              <input
                style={{ width: "200px" }}
                id="telefononumero"
                className="form-control"
                type="text"
                maxLength="15"
                onChange={this.handleTelefonoNumero}
                onBlur={this.handleTelefonoNumero}
                value={this.state.telefonoNumero}
              />
            </div>
          </div>
          <div>Ingrese el celular sin 0 ni 15</div>
          <br />
          <div>
            E-Mail <span style={{ color: "red" }}>*</span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                placeholder="E-mail"
                id="email"
                className="form-control"
                type="text"
                maxLength="60"
                onChange={this.handleEmail}
                onBlur={this.handleEmail}
                value={this.state.email}
              />
            </div>
          </div>
         <div style={{ marginTop: "5px" }}>
            Horario de contacto: De 0hs a 24hs{" "}
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className="d-flex">
            <div>
              <input
                placeholder="Hs Desde"
                style={{ width: "100px" }}
                id="horadesde"
                className="form-control"
                type="text"
                maxLength="2"
                onChange={this.handleHoraDesde}
                value={this.state.horaDesde}
                onKeyPress={this.soloNumeros}
              />
            </div>
            <div>
              <input
                placeholder="Hs Hasta"
                style={{ width: "100px" }}
                id="horahasta"
                className="form-control"
                type="text"
                maxLength="2"
                onChange={this.handleHoraHasta}
                value={this.state.horaHasta}
                onKeyPress={this.soloNumeros}
              />
            </div>
          </div>
          <br />
          <div style={{paddingBottom: '150px'}}>
            <span style={{ color: "red" }}>*</span> Datos obligatorios
          </div>
          <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
            <div className="d-flex justify-content-center align-items-center"
              style={{
                background: "#224372",
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: "16px",
                width: "100px",
                height: "33px"
              }}
              onClick={() => {
                var valido = this.datosPersonalesMarcarCamposRequeridos();
                  if (valido) {
                  this.scrollTo();
                  this.props.hideMenu(true);
                  this.setState({ seccionDatosPersonales: false });
                  this.setState({ seccionDireccion: true });
                  this.setState({ seccionElegiTuPlan: false });
                }
              }}
            >
              Continuar
            </div>
          </div>
        </form>
      </div>
    );
  };

  datosPersonalesMarcarCamposRequeridos = () => {
    this.state.alert = [];
    var valido = true;
    if (!this.state.nroDocumento) {
      this.state.alert.push("* Debes ingresar un numero de documento");
      valido = false;
    } 

    if (!this.state.apellido) {
      this.state.alert.push("* Debes ingresar el apellido");
      valido = false;
    }

    if (!this.state.nombre) {
      this.state.alert.push("* Debes ingresar el nombre");
      valido = false;
    }

    if (!this.state.fechaNacimiento) {
      this.state.alert.push("* Debes ingresar una fecha de nacimiento valida");
      valido = false;
    }

    if (this.state.fechaNacimiento && isNaN(Date.parse(this.state.fechaNacimiento))) {
      this.state.alert.push("* Debes ingresar una fecha de nacimiento valida");
      valido = false;
    }

    var minDate = '01/01/1900';
    if (this.state.fechaNacimiento && Date.parse(this.state.fechaNacimiento) <= Date.parse(minDate)) {
      this.state.alert.push("* La fecha de nacimiento debe ser mayor al 01/01/1900");
      valido = false;
    }

    var today = new Date();
    if (this.state.fechaNacimiento && Date.parse(this.state.fechaNacimiento) > today) {
      this.state.alert.push("* La fecha de nacimiento no debe ser mayor a la fecha de hoy");
      valido = false;
    }


    if (!this.state.genero) {
      this.state.alert.push("* Debes ingresar el genero");
      valido = false;
    } 

    if (!this.state.celularFijo) {
      this.state.alert.push("* Debes ingresar el tipo de telefono");
      valido = false;
    } 

    let phoneEx =
      /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g;
    if (
      !this.state.telefonoNumero ||
      !this.state.telefonoNumero.match(phoneEx)
    ) {
      this.state.alert.push("* Debes ingresar un telefono valido");
      valido = false;
    }

    if (!this.state.email) {
      this.state.alert.push("* Debes ingresar el email");
      valido = false;
    } 

    if (!this.state.horaDesde || !this.state.horaHasta) {
      if (!this.state.horaDesde) {
        this.state.alert.push("* Debes ingresar la hora desde de contacto");
        valido = false;
      } 

      if (!this.state.horaHasta) {
        this.state.alert.push("* Debes ingresar la hora hasta de contacto");
        valido = false;
      } 
    } else {
      if (
        this.state.horaHasta &&
        this.state.horaDesde &&
        parseInt(this.state.horaHasta) <= parseInt(this.state.horaDesde)
      ) {
        this.state.alert.push(
          "* La hora hasta de contacto debe ser mayor que la hora desde"
        );
        valido = false;
      } 
    }

    if (!valido) {
      this.handleOpen();
    }

    return valido;
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  getProvincias = async () => {
    this.setState({ partidoId: null });
    var optionsPartido = [{ value: -1, label: "Elige el partido" }];
    this.setState({
      listarPartidos: optionsPartido,
    });

    this.setState({ localidadId: null });
    var optionsLocalidad = [{ value: -1, label: "Elige la localidad" }];
    this.setState({
      listarLocalidades: optionsLocalidad,
    });

    const data = {
      PalabraABuscar: "",
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    //la api no requiere permiso
    const respuesta = await fetch(
      urlServer + "/api/domicilio/provincia/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        this.validarVencimientoToken(result.message);
        let options = result.map((prov) => {
          const value = {
            value: prov.provinciaId,
            label: prov.descripcion,
          };
          return value;
        });
        options = [{ value: -1, label: "Elige la provincia" }, ...options];
        this.setState({
          listarProvincias: options,
        });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  getPartidos = async (e) => {
    this.setState({ provinciaId: parseInt(e.target.value) });
    this.setState({
      provinciaDescripcion: e.target.selectedOptions[0].innerText,
    });

    // Limpiar localidad
    this.setState({ localidadId: null });
    var optionsLocalidad = [{ value: -1, label: "Elige la localidad" }];
    this.setState({
      listarLocalidades: optionsLocalidad,
    });

    this.setState({ partidoId: -1 });

    // -------------

    const data = {
      provinciaId: parseInt(e.target.value),
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    //la api no requiere permiso
    const respuesta = await fetch(
      urlServer + "/api/domicilio/departamento/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .then(async (result) => {
        this.validarVencimientoToken(result.message);
        let options = result.map((dpto) => {
          const value = {
            value: dpto.partidoDepartamentoId,
            label: dpto.descripcion,
          };
          return value;
        });
        options = [{ value: -1, label: "Elige el partido" }, ...options];

        this.setState({ listarPartidos: options });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  getLocalidades = async (e) => {
    this.setState({ partidoId: parseInt(e.target.value) });
    this.setState({ localidadId: -1 });

    const data = {
      ProvinciaId: this.state.provinciaId,
      PartidoDepartamentoId: parseInt(e.target.value),
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(
      urlServer + "/api/domicilio/localidad/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .then(async (result) => {
        this.validarVencimientoToken(result.message);
        let options = result.map((loc) => {
          const value = {
            value: loc.localidadId,
            label: loc.descripcion,
          };
          return value;
        });
        options = [{ value: -1, label: "Elige la localidad" }, ...options];

        this.setState({
          listarLocalidades: options,
        });
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  handleCodigoPostal = (e) => {
    if(e.type === 'change')
    {
      this.setState({ codigoPostal: e.target.value });
    }
    else {
      this.setState({ codigoPostal: e.target.value.trim() });
    }
  };

  handleCountryBarrio = (e) => {
    if(e.type === 'change')
    {
      this.setState({ countryBarrio: e.target.value });
    }
    else {
      this.setState({ countryBarrio: e.target.value.trim() });
    }
  };

  handleCalle = (e) => {
    if(e.type === 'change')
    {
      this.setState({ calle: e.target.value });
    }
    else {
      this.setState({ calle: e.target.value.trim() });
    }
  };

  handleLocalidad = (e) => {
    this.setState({ localidadId: parseInt(e.target.value) });
    this.setState({
      localidadDescripcion: e.target.selectedOptions[0].innerText,
    });
  };

  handleCalleNro = (e) => {
    if(e.type === 'change')
    {
      this.setState({ calleNro: e.target.value });
    }
    else {
      this.setState({ calleNro: e.target.value.trim() });
    }
  };

  handlePiso = (e) => {
    if(e.type === 'change') {
      this.setState({ piso: e.target.value });
    }
    else {
      this.setState({ piso: e.target.value.trim() });
    }
  };

  handleDpto = (e) => {
    if(e.type === 'change')
    {
      this.setState({ departamento: e.target.value });
    }
    else {
      this.setState({ departamento: e.target.value.trim() });
    }
  };

  handleTorre = (e) => {
    if(e.type === 'change')
    {
      this.setState({ torre: e.target.value });
    }
    else {
      this.setState({ torre: e.target.value.trim() });
    }
  };

  handleCuerpo = (e) => {
    if(e.type === 'change')
    {
      this.setState({ cuerpo: e.target.value });
    }
    else {
      this.setState({ cuerpo: e.target.value.trim() });
    }
  };

  handleCalleLateral1 = (e) => {
    if(e.type === 'change')
    {
      this.setState({ calleLateral1: e.target.value });
    }
    else {
      this.setState({ calleLateral1: e.target.value.trim() });
    }
  };

  handleCalleLateral2 = (e) => {
    if(e.type === 'change')
    {
      this.setState({ calleLateral2: e.target.value });
    }
    else {
      this.setState({ calleLateral2: e.target.value.trim() });
    }
  };

  direccionMarcarCamposRequeridos = () => {
    this.state.alert = [];
    var valido = true;

    if (!this.state.provinciaId) {
      this.state.alert.push("* Debes seleccionar la provincia");
      valido = false;
    }

    if (!this.state.partidoId || this.state.partidoId === -1) {
      this.state.alert.push("* Debes seleccionar el partido");
      valido = false;
    } 

    if (!this.state.localidadId || this.state.localidadId === -1) {
      this.state.alert.push("* Debes seleccionar la localidad");
      valido = false;
    } 

    if (!this.state.codigoPostal) {
      this.state.alert.push("* Debes seleccionar el codigo postal");
      valido = false;
    } 

    if (!this.state.calle) {
      this.state.alert.push("* Debes seleccionar la calle");
      valido = false;
    }

    if (!this.state.calleNro) {
      this.state.alert.push("* Debes seleccionar la altura");
      valido = false;
    } 

    if (!valido) {
      this.handleOpen();
    }

    return valido;
  };

  renderSeccionDireccion = () => {
    return (
      <div>
        <form>
                <div className="row" >
            <div className="col-md-6">
              <div>
                Provincia <span style={{ color: "red" }}>*</span>
              </div>
              <select
                id="provincia"
                className="alta-suscripciones-select form-control"
                onChange={this.getPartidos}
                value={this.state.provinciaId}
              >
                {this.state.listarProvincias.map((prov, index) => (
                  <option key={index} value={prov.value}>
                    {prov.label}
                  </option>
                ))}
              </select>
            </div>
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>
                Partido <span style={{ color: "red" }}>*</span>
              </div>
              <select
                id="partido"
                className="alta-suscripciones-select form-control"
                onChange={this.getLocalidades}
                value={this.state.partidoId}
                disabled={!this.state.provinciaId}
              >
                {this.state.listarPartidos.map((part, index) => (
                  <option key={index} value={part.value}>
                    {part.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row" style={{marginTop:"5px"} }>
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>
                Localidad / Barrio <span style={{ color: "red" }}>*</span>
              </div>
              <select
                id="localidad"
                className="alta-suscripciones-select form-control"
                value={this.state.localidadId}
                onChange={this.handleLocalidad}
                disabled={!this.state.partidoId || this.state.partidoId === -1}
              >
                {this.state.listarLocalidades.map((loc, index) => (
                  <option key={index} value={loc.value}>
                    {loc.label}
                  </option>
                ))}
              </select>
            </div>
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>
                Código Postal <span style={{ color: "red" }}>*</span>
              </div>
              <input
                id="codigopostal"
                style={{ width: "150px" }}
                className="form-control"
                type="text"
                maxLength="10"
                onChange={this.handleCodigoPostal}
                onBlur={this.handleCodigoPostal}
                value={this.state.codigoPostal}
                disabled={
                  !this.state.localidadId || this.state.localidadId === -1
                }
              />
            </div>
          </div>
          <div className="row">
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>Country / Barrio</div>
              <div>
                <input
                  className="form-control"
                  type="text"
                  maxLength="50"
                  onChange={this.handleCountryBarrio}
                  onBlur={this.handleCountryBarrio}
                  value={this.state.countryBarrio}
                  disabled={
                    !this.state.codigoPostal || this.state.codigoPostal === ""
                  }
                />
              </div>
            </div>
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>
                Calle <span style={{ color: "red" }}>*</span>
              </div>
              <div>
                <input
                  id="calle"
                  className="form-control"
                  type="text"
                  maxLength="50"
                  onChange={this.handleCalle}
                  onBlur={this.handleCalle}
                  value={this.state.calle}
                  disabled={
                    !this.state.codigoPostal || this.state.codigoPostal === ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div style={{ display: "inline-block", width: "100px" }}>
                <div>
                  Nro <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <input
                    id="callenro"
                    className="form-control"
                    type="text"
                    maxLength="10"
                    onChange={this.handleCalleNro}
                    onBlur={this.handleCalleNro}
                    value={this.state.calleNro}
                    disabled={
                      !this.state.codigoPostal || this.state.codigoPostal === ""
                    }
                  />
                </div>
              </div>
                        <div style={{ display: "inline-block", width: "100px ", marginTop: "5px" }}>
                <div>Piso</div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    maxLength="10"
                    onChange={this.handlePiso}
                    onBlur={this.handlePiso}
                    value={this.state.piso}
                    disabled={
                      !this.state.codigoPostal || this.state.codigoPostal === ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>Calle Lateral 1</div>
              <div>
                <input
                  className="form-control"
                  type="text"
                  maxLength="50"
                  onChange={this.handleCalleLateral1}
                  onBlur={this.handleCalleLateral1}
                  value={this.state.calleLateral1}
                  disabled={
                    !this.state.codigoPostal || this.state.codigoPostal === ""
                  }
                />
              </div>
            </div>
                    <div className="col-md-6" style={{ marginTop: "5px" }}>
              <div>Calle Lateral 2</div>
              <div>
                <input
                  className="form-control"
                  type="text"
                  maxLength="50"
                  onChange={this.handleCalleLateral2}
                  onBlur={this.handleCalleLateral2}
                  value={this.state.calleLateral2}
                  disabled={
                    !this.state.codigoPostal || this.state.codigoPostal === ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
                        <div style={{ display: "inline-block", width: "100px ", marginTop: "5px" }}>
                <div>Dpto</div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    maxLength="10"
                    onChange={this.handleDpto}
                    onBlur={this.handleDpto}
                    value={this.state.departamento}
                    disabled={
                      !this.state.codigoPostal || this.state.codigoPostal === ""
                    }
                  />
                </div>
              </div>
                        <div style={{ display: "inline-block", width: "100px ", marginTop: "5px" }}>
                <div>Torre</div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    maxLength="10"
                    onChange={this.handleTorre}
                    onBlur={this.handleTorre}
                    value={this.state.torre}
                    disabled={
                      !this.state.codigoPostal || this.state.codigoPostal === ""
                    }
                  />
                </div>
              </div>
                        <div style={{ display: "inline-block", width: "100px ", marginTop: "5px" }}>
                <div>Cuerpo</div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    maxLength="10"
                    onChange={this.handleCuerpo}
                    onBlur={this.handleCuerpo}
                    value={this.state.cuerpo}
                    disabled={
                      !this.state.codigoPostal || this.state.codigoPostal === ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style={{paddingBottom: '150px'}}>
            <span style={{ color: "red" }}>*</span> Datos obligatorios
          </div>
          <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
            <div className="d-flex justify-content-center align-items-center"
              style={{
                  background: "#224372",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "16px",
                  width: "100px",
                  height: "33px"
              }}
            onClick={() => {
                this.scrollTo();
                this.props.hideMenu(false);
                this.setState({ seccionDatosPersonales: true });
                this.setState({ seccionDireccion: false });
                this.setState({ seccionElegiTuPlan: false });
              }}
            >
              Volver
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-center"
              style={{
                  background: "#224372",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "16px",
                  width: "100px",
                  height: "33px"
              }}
              onClick={() => {
                var valido = this.direccionMarcarCamposRequeridos();
                  if (valido) {
                  this.scrollTo();
                  this.setState({ seccionDatosPersonales: false });
                  this.setState({ seccionDireccion: false });
                  this.setState({ seccionElegiTuPlan: true });
                }
              }}
            >
              Continuar
            </div>
          </div>
        </form>
      </div>
    );
  };

  handleClickDay = (prod, dia) => {
    var antes =
      prod.domingo +
      prod.lunes +
      prod.martes +
      prod.miercoles +
      prod.jueves +
      prod.viernes +
      prod.sabado;

    switch (dia) {
      case 1: //Domingo
        prod.domingo = prod.domingo === 0 ? 1 : 0;
        break;
      case 2: //Lunes
        prod.lunes = prod.lunes === 0 ? 1 : 0;
        break;
      case 3: //Martes
        prod.martes = prod.martes === 0 ? 1 : 0;
        break;
      case 4: //Miercoles
        prod.miercoles = prod.miercoles === 0 ? 1 : 0;
        break;
      case 5: //Jueves
        prod.jueves = prod.jueves === 0 ? 1 : 0;
        break;
      case 6: //Viernes
        prod.viernes = prod.viernes === 0 ? 1 : 0;
        break;
      case 7: //Sabado
        prod.sabado = prod.sabado === 0 ? 1 : 0;
        break;
    }
    var despues =
      prod.domingo +
      prod.lunes +
      prod.martes +
      prod.miercoles +
      prod.jueves +
      prod.viernes +
      prod.sabado;
    if (despues === 0) {
      prod.cantidad = 0;
    }
    if (despues === 1 && antes === 0) {
      prod.cantidad = 1;
    }
    var index = this.state.suscripcionProductosListado.findIndex(
      (x) => x.productoId === prod.productoId
    );
    this.state.suscripcionProductosListado[index] = prod;
    this.setState({
      suscripcionProductosListado: this.state.suscripcionProductosListado,
    });
  };

  renderSeccionElegiPlan = () => {
    return this.state.suscripcionProductosListado.map((prod, index) => {
      return (
        <div key={index} className="suscripciones-prod-card">
          <div className="pb-3 d-flex justify-content-center">
            {prod.descripcion}
          </div>
          <div style={{display: prod.tipo !== 'Diarios' ? 'flex' : 'none', justifyContent: 'center', minHeight: '170px', alignItems: 'start' }}>
              <img src={`${tiendaUrl}/${prod.codigoEditor}.png`} 
                   onError={this.addDefaultSrc} 
                   style={{ maxWidth: "150px" }} 
                   alt="placeholder" 
              />                            
          </div>
          <div 
            style={{
              display: prod.tipo === "Diarios" ? "block" : "none", minHeight: '170px', alignItems: 'start'
            }}
          >
            <div className="d-flex justify-content-center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.lunes === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.lunes === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 2);
                }}
              >
                LU
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.martes === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.martes === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 3);
                }}
              >
                MA
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.miercoles === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.miercoles === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 4);
                }}
              >
                MI
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.jueves === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.jueves === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 5);
                }}
              >
                JU
              </div>
            </div>

            <div className="mt-3 mb-4 d-flex justify-content-center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.viernes === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.viernes === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 6);
                }}
              >
                VI
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.sabado === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.sabado === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 7);
                }}
              >
                SA
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "20px",
                  border:
                    prod.domingo === 1 ? "1px solid white" : "1px solid gray",
                  backgroundColor: prod.domingo === 1 ? "yellow" : "white",
                  padding: "6px 10px",
                  width: "50px",
                }}
                onClick={() => {
                  this.handleClickDay(prod, 1);
                }}
              >
                DO
              </div>
              &nbsp;
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center elemento-botones-input">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                cursor: "pointer",
                background: "#F4F4F4",
                width: "26px",
                height: "26px",
                borderRadius: "50%",
                padding: "5px",
              }}
              onClick={() => {
                if (prod.cantidad > 0) {
                  prod.cantidad--;
                  if (prod.cantidad === 0 && prod.tipo === "Diarios") {
                    prod.lunes = 0;
                    prod.martes = 0;
                    prod.miercoles = 0;
                    prod.jueves = 0;
                    prod.viernes = 0;
                    prod.sabado = 0;
                    prod.domingo = 0;
                  }
                  let { suscripcionProductosListado } = this.state;
                  let ind = suscripcionProductosListado.findIndex(
                    (e) => e.productoId === prod.productoId
                  );
                  suscripcionProductosListado[ind] = prod;
                  this.setState({ suscripcionProductosListado });
                }
              }}
            >
              <ReactSVG
                src={restar}
                style={{ color: "#8E95A5", width: "11px" }}
              />
            </div>
            <div
              className="f-13-5"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              <input
                style={{
                  backgroundColor: "white",
                  border: "#343435 solid 1px",
                }}
                type="number"
                className="input-of-cantidades text-center"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                min="0"
                max="999"
                maxLength="3"
                id={prod.productoId}
                disabled={
                  prod.domingo +
                    prod.lunes +
                    prod.martes +
                    prod.miercoles +
                    prod.jueves +
                    prod.viernes +
                    prod.sabado <
                  1
                }
                value={prod.cantidad}
                autoComplete="off"
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    let { suscripcionProductosListado } = this.state;
                    prod.cantidad = 0;
                    if (prod.tipo === "Diarios") {
                      prod.lunes = 0;
                      prod.martes = 0;
                      prod.miercoles = 0;
                      prod.jueves = 0;
                      prod.viernes = 0;
                      prod.sabado = 0;
                      prod.domingo = 0;
                    }
                    let ind = suscripcionProductosListado.findIndex(
                      (e) => e.productoId === prod.productoId
                    );
                    suscripcionProductosListado[ind] = prod;
                    this.setState({ suscripcionProductosListado });
                  }
                }}
                onChange={(e) => {
                  let { suscripcionProductosListado } = this.state;
                  let regex = /[^0-9]/g;
                  if (regex.test(e.target.value)) {
                    e.target.value = e.target.value.replace(regex, "");
                  }
                  e.target.value =
                    e.target.value === "0"
                      ? e.target.value
                      : e.target.value.replace(/^(0+)/g, "");
                  prod.cantidad =
                    e.target.value === "" ? "empty" : parseInt(e.target.value);
                  if (prod.cantidad === 0 && prod.tipo === "Diarios") {
                    prod.lunes = 0;
                    prod.martes = 0;
                    prod.miercoles = 0;
                    prod.jueves = 0;
                    prod.viernes = 0;
                    prod.sabado = 0;
                    prod.domingo = 0;
                  }
                  let ind = suscripcionProductosListado.findIndex(
                    (e) => e.productoId === prod.productoId
                  );
                  suscripcionProductosListado[ind] = prod;
                  this.setState({ suscripcionProductosListado });
                }}
              />
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                borderRadius: "50%",
                padding: "5px",
                pointerEvents:
                  prod.domingo +
                    prod.lunes +
                    prod.martes +
                    prod.miercoles +
                    prod.jueves +
                    prod.viernes +
                    prod.sabado <
                  1
                    ? "none"
                    : "auto",
                background: "#F4F4F4",
              }}
              onClick={() => {
                prod.cantidad++;
                let { suscripcionProductosListado } = this.state;
                let ind = suscripcionProductosListado.findIndex(
                  (e) => e.productoId === prod.productoId
                );
                suscripcionProductosListado[ind] = prod;
                this.setState({ suscripcionProductosListado });
              }}
            >
              <ReactSVG
                src={sumar}
                style={{ color: "#8E95A5", width: "11px" }}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  getCantidadCarrito = () => {
    var cantidadCarrito = 0;
    this.state.suscripcionProductosListado.map((prod, index) => {
      if (prod.tipo === "Diarios") {
        var aux =
          prod.domingo +
          prod.lunes +
          prod.martes +
          prod.miercoles +
          prod.jueves +
          prod.viernes +
          prod.sabado;
        if (prod.cantidad > 0 && aux > 0) {
          cantidadCarrito += 1;
        }
      } else {
        if (prod.cantidad > 0) {
          cantidadCarrito += 1;
        }
      }
    });

    return cantidadCarrito;
  };

  volverDeCarrito = () => {
    this.setState({ verCarrito: false });
    this.props.hideMenu(false);
  };

    volverDeConfirmarCarrito = () => {
        this.setState({ tipoDocumento: { value: "DNI", label: "DNI" } });
        this.setState({ nroDocumento: null });
        this.setState({ apellido: null });
        this.setState({ nombre: null });
        this.setState({ fechaNacimiento: null });
        this.setState({ genero: null });
        this.setState({ celularFijo: null });
        this.setState({ telefonoNumero: null });
        this.setState({ email: null });
        this.setState({ horaDesde: null });
        this.setState({ horaHasta: null });
        this.setState({ datosPersonalesCompletos: false });
        this.setState({ provinciaId: null });
        this.setState({ partidoId: null });
        this.setState({ listarPartidos: [] });
        this.setState({ localidadId: null });
        this.setState({ localidadDescripcion: null });
        this.setState({ listarLocalidades: [] });
        this.setState({ localidadBarrio: null });
        this.setState({ codigoPostal: null });
        this.setState({ countryBarrio: null });
        this.setState({ calle: null });
        this.setState({ calleNro: null });
        this.setState({ piso: null });
        this.setState({ departamento: null });
        this.setState({ torre: null });
        this.setState({ cuerpo: null });
        this.setState({ calleLateral1: null });
        this.setState({ calleLateral2: null });
        this.setState({ datosDireccionCompletos: false });
        
        this.getSuscripcionProductos();
        
        this.setState({ seccionDatosPersonales: true });
        this.setState({ seccionDireccion: false });
        this.setState({ seccionElegiTuPlan: false });
        
        this.setState({ verCarrito: false });

        this.props.hideMenu(true);    
        this.setState({ seGuardo: true });
       
  };

    redireccionar() {
        if (this.state.seGuardo) {
          return <Redirect to={{
              pathname: '/Suscripciones/MisSolicitudes'
                }} />
        }
  }
  render() {
    const handleClose = () => {
      this.setState({ open: false });
    };

      return (
        
      <React.Fragment>
        {this.state.verCarrito === true ? (
          <div>
            <CarritoAltaSuscripciones
              volverDeCarrito={this.volverDeCarrito}
              volverDeConfirmarCarrito={this.volverDeConfirmarCarrito}
              suscripcion={this.state}
            />
          </div>
              ) : (
            <React.Fragment>
                {this.redireccionar()}
            { !this.state.seccionDatosPersonales  ?
              <div
                  id="backarrow"
                  className="position-fixed back-arrow-box"
                    onClick={() => {
                        this.scrollTo();
                      if(this.state.seccionDireccion)
                      {
                        this.props.hideMenu(false);
                        this.setState({ seccionDatosPersonales: true });
                        this.setState({ seccionDireccion: false });
                        this.setState({ seccionElegiTuPlan: false });
                      }
                      else if(this.state.seccionElegiTuPlan) {
                        this.setState({ seccionDatosPersonales: false });
                        this.setState({ seccionDireccion: true });
                        this.setState({ seccionElegiTuPlan: false });
                      }
                  }}
                >
                <ReactSVG src={backArrow} />
              </div>
            :null}
            <div id="altasuscripcion" className="container text-left">
              <div className="d-flex justify-content-between">
                <div>
                  <Title classes="" title="Solicitar Suscripción" />
                </div>
                {this.state.seccionElegiTuPlan === true && (
                  <div
                    className="position-relative"
                    style={{ marginTop: "33px" }}
                  >
                    <ReactSVG
                      src={pedidos}
                      style={{
                        width: "27px",
                        height: "27px",
                        color: "#8E95A5",
                      }}
                    />
                    <div
                      className="position-absolute d-flex justify-content-center align-items-center f-11"
                      style={{
                        right: "-8px",
                        top: "-8px",
                        background: "#EA3F3F",
                        color: "white",
                        borderRadius: "50%",
                        width: "16px",
                        height: "16px",
                      }}
                    >
                      {this.getCantidadCarrito()}
                    </div>
                  </div>
                )}
                </div>
              <div>
                <div
                  className="tabs d-flex justify-content-between w-100 "
                  style={{
                    borderBottom: "1px solid gray",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    className={
                      "tablinks col-4 text-center" +
                      (this.state.seccionDatosPersonales ? " active" : "")
                    }
                  >
                    Datos Personales
                  </div>
                  <div
                    className={
                      "tablinks col-4 text-center" +
                      (this.state.seccionDireccion ? " active" : "")
                    }
                  >
                    Direcci&oacute;n
                  </div>
                  <div
                    className={
                      "tablinks col-4 text-center" +
                      (this.state.seccionElegiTuPlan ? " active" : "")
                    }
                  >
                    Eleg&iacute; tu plan
                  </div>
                                  </div>
                 {/*debugger*/}
                {this.state.seccionDatosPersonales && (

                  <div>{this.renderSeccionDatosPersonales()}</div>
                )}
                {this.state.seccionDireccion && (
                  <div>{this.renderSeccionDireccion()}</div>
                )}
                {this.state.seccionElegiTuPlan && (
                  <div>
                    <div className="d-flex justify-content-start flex-wrap" style={{paddingBottom: '150px'}}>
                      {this.renderSeccionElegiPlan()}
                    </div>
                    <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                      <div className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#224372",
                            color: "white",
                            fontSize: "12px",
                            textAlign: "center",
                            cursor: "pointer",
                            borderRadius: "16px",
                            width: "100px",
                            height: "33px"
                        }}
                        onClick={() => {
                          this.scrollTo();
                          this.setState({ seccionDatosPersonales: false });
                          this.setState({ seccionDireccion: true });
                          this.setState({ seccionElegiTuPlan: false });
                        }}
                      >
                        Volver
                      </div>
                      &nbsp;
                      <div className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#224372",
                            color: "white",
                            fontSize: "12px",
                            textAlign: "center",
                            cursor: "pointer",
                            borderRadius: "16px",
                            width: "100px",
                            height: "33px"
                        }}
                        onClick={() => {
                          var cantidadcarrito = this.getCantidadCarrito();
                          if (cantidadcarrito > 0) {
                            this.setState({ verCarrito: true });
                            this.props.hideMenu(true);
                          } else {
                            this.state.alert = [];
                            this.state.alert.push(
                              "* Debes seleccionar algun producto"
                            );
                            this.handleOpen();
                          }
                        }}
                      >
                        Continuar
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* MODAL INFO TELEFONO */}
            <div
              className="modal fade"
              id="modalInfoTelefono"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalInfoTelefonoLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog-centered modal-dialog justify-content-center"
                role="document"
              >
                <div className="modal-content" style={{ background: "#FFF" }}>
                  <div className="modal-body f-15 text-left">
                    <ul>
                      <li>Acepta solo números y sin espacios.</li>
                      <li>
                        Debes escribir el código de área y seguido el número de
                        teléfono.
                      </li>
                      <ul>
                        {" "}
                        <strong>Ejemplos:</strong>
                        <li>1151027777</li>
                        <li>1145183833</li>
                        <li>3411118888</li>
                        <li>2611234567</li>
                      </ul>
                      <li>
                        No acepta el valor 15 en el código de área, en su lugar
                        podes colocar 11.
                      </li>
                    </ul>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary f-13"
                      data-dismiss="modal"
                      style={{ color: "#EA3F3F" }}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* MODAL INFO TELEFONO */}
            {/* MODAL QUE MUESTRA LOS ERRORES  */}
            <div
              className="modal fade"
              id="errorloginModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="errorloginModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog-centered modal-dialog justify-content-center"
                role="document"
              >
                <div
                  className="modal-content"
                  style={{ background: "#EA3F3F" }}
                >
                  <div className="modal-body f-15 text-center">
                    <ul className="lista-alertas">
                      {this.state.alert.map((al, index) => {
                        return <li key={index}> {al} </li>;
                      })}
                    </ul>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary f-13"
                      data-dismiss="modal"
                      id="btnAceptar"
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              id="modal"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#errorloginModal"
            ></button>
            {/* // MODAL QUE MUESTRA LOS ERRORES // */}

            <Modal open={this.state.open} onClose={handleClose}>
              <div
                style={{
                  backgroundColor: "#EA3F3F",
                  color: "white",
                  padding: "16px",
                  maxWidth: "400px",
                  width: "85%",
                  height: "auto",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "15px",
                  fontFamily: "roboto",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
              >
                <div align="center">
                  <ul className="lista-alertas">
                    {this.state.alert.map((al, index) => {
                      return <li key={index}> {al} </li>;
                    })}
                  </ul>
                </div>
                <div align="right" style={{ marginTop: "15px" }}>
                  <button
                    style={{
                      backgroundColor: "#EA3F3F",
                      borderWidth: "0px",
                      fontSize: "13px",
                      color: "white",
                    }}
                    type="button"
                    onClick={handleClose}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </Modal>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
