const getNombreYapellido = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const nombreYappelido = {
        nombre: infoToken.nombre,
        apellido: infoToken.apellido
    };
    return nombreYappelido
}

const getTelefono = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const telefono = infoToken.telefono;

    return telefono
}

const getUsuarioId = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const usuario_id = infoToken.usuario_id;

    return usuario_id
}

const getPuntoVentaIdId = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const entidad_id = infoToken.entidad_id;

    return entidad_id
}


const getEmail = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const email = infoToken.sub

    return email
}

const getPermisos = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const permisos = infoToken ? infoToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] : []

    return permisos
}

const getExpiracionToken = () => {
    let expiracion = localStorage.getItem('expires_at')

    return expiracion    
}

const getUltimoModuloAlmacenado = () => {
    let ultimoModuloAlmacenado = localStorage.getItem('UltimoModulo');

    return ultimoModuloAlmacenado
}

const setUltimoModuloAlmacenado = () => {
    var modulo = window.location.pathname;
    localStorage.setItem('UltimoModulo', modulo);
}

export { getTelefono, getNombreYapellido, getEmail, getUsuarioId, getPuntoVentaIdId, getPermisos, getExpiracionToken, getUltimoModuloAlmacenado, setUltimoModuloAlmacenado}