import React, { useEffect, useState } from "react";
import './modalproductonoeditorial.css';
import Carousel from 'react-bootstrap/Carousel';
import tienda from '../assets/ic_cc.svg';
import { ReactSVG } from 'react-svg';
import cross from '../assets/cross.svg';

function ModalProductoNoEditorial(props) {

    const [banners, setBanners] = useState([])

    useEffect(() => {
        const tiendaUrl = 'https://paradaonline.com.ar/s/img/tapas';
        const cantidadImagenes = props.cantidadImagenes;
        var arr = [];
        for(var a = 1; a <= cantidadImagenes; a++)
        {
            arr.push({urlBanner: `${tiendaUrl}/${props.edicioncodigoEditor}${a}.png`})
        }
        setBanners(arr);
    }, []);

    const addDefaultSrc = (ev) => {
        ev.target.src = tienda
    }

    return (
        <div className="modal-noedit" id="productoNoEditorial">
            <div className="modal-content-noedit" >
                <div className="modal-header d-flex">
                    <h6 className="modal-title" style={{overflowWrap: 'anywhere'}}>
                        {props.titulo}
                    </h6>
                    <ReactSVG src={cross} onClick={props.volver} style={{height: '10px', width: '10px', color: 'gray'}} />
                </div>
                <div className="modal-body" style={{textAlign: 'left', height: '390px'}}>
                    <div>
                        <Carousel className="carousel">
                            {banners.map((banner, index) => {
                                return (
                                    <Carousel.Item interval={3000} key={index}>
                                        <img style={{height: '200px'}}
                                            className="d-block w-100 carousel-img"
                                            src={banner.urlBanner}
                                            onError={addDefaultSrc}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div className="d-flex justify-content-end" style={{marginTop:'10px',marginBottom:'5px'}}>
                        <span className="priceItem" style={{ maxHeight: "18px", fontSize: '15px', fontWeight:'bold', height: '16px'}}>${props.productoNoEditorialPrecioVenta.toFixed(2)}</span>
                    </div>
                    <div className="text-justify">
                        {props.children}
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}

export default ModalProductoNoEditorial